import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';

let PrivacyPolisies = (props) => {
	return <StyledWrapper { ...props }>
		Privacy Polisies page	
	</StyledWrapper>;
};

PrivacyPolisies = React.memo(PrivacyPolisies);
PrivacyPolisies.defaultProps = {
};
PrivacyPolisies.propTypes = {
};

export default PrivacyPolisies;
