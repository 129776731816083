
const roleStatus = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Statuses',

	pageUrl: 'roles/statuses',
	pageTitle: 'Statuses',
};

export default roleStatus;
