import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let Email = ({ storeFormName, ...props }) => {
	return <StyledWrapper { ...props } hiddenLabel error={props.error && <ErrorText title={props.error} />} />;
};

Email = React.memo(Email);
Email.defaultProps = {
};
Email.propTypes = {
};

export default Email;
