import styled from 'styled-components';
import Paper from '../../../Paper.jsx';

const Wrapper = styled(Paper)`
    position: fixed;
    bottom: 0px;
    z-index: 100;
    background: #FFFFFF;
    box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.08);
    padding: 16px 0px 21px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 601px) {
        display: none;
    }
`;

export default Wrapper