import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import { 
	selectorMainExtract,
	actionApiFormCreateFile,
	actionApiFormProp,
} from '@nest-datum-ui/Store';
import { objFileList as utilsCheckObjFileList } from '@nest-datum-utils/check';
import Field from '@nest-datum-ui/Field';
import Typography from 'components/Typography';
import TypographySuccess from 'components/Typography/Success';
import TypographyError from 'components/Typography/Error';
import PaperDashed from 'components/Paper/Dashed';
import InputUpload from 'components/Input/Upload';
import ButtonPrimary from 'components/Button/Primary';
import StyledWrapper from './Styled/Wrapper.jsx';

/**
 * https://i.imgur.com/NUNuOSo.png
 * https://i.imgur.com/3iIktUR.png
 */
let UploadCv = ({ onSubmit, ...props }) => {
	const routeName = React.useContext(ContextRoute);
	const { 
		[routeName]: { 
			form: { 
				storeName, 
				id, 
				nextPageUrl, 
			}, 
		}, 
	} = React.useContext(ContextProps);
	const [ message, setMessage ] = React.useState(() => false);
	const loader = useSelector(selectorMainExtract([ 'api', 'form', storeName, 'loader' ]));
	const value = useSelector(selectorMainExtract([ 'api', 'form', storeName, 'file' ]));
	const fileSelectedFlag = utilsCheckObjFileList(value);
	const onSubmitWrapper = React.useCallback((e) => {
		const files = e.target.files;

		files['systemId'] = 'files-system-cv';
		files['parentId'] = 'files-folder-cv';

		actionApiFormProp(storeName, 'loader', true)(() => {
			actionApiFormCreateFile(process.env.URL_API_FILES_FILE, { files })(() => {
				actionApiFormProp(storeName, 'loader', false)();
			});
		});
		setMessage(true);
	}, [
		storeName,
	]);

	useEffect(() => {
		window.history.pushState("","","")
	})

	return <StyledWrapper { ...props }
		storeName={storeName} 
		id={id}>
		{(loader === false || loader === undefined) 
		&& <React.Fragment>
			<PaperDashed>
				<Field
					Component={InputUpload}
					form={id}
					name="file"
					label="Upload CV"
					accept="application/pdf,application/doc,application/docx"
					onChange={onSubmitWrapper} />
				{message
					? <TypographyError sx={{ color: 'red' }}>
						An error occurred during processing. Try to build CV manually or upload another file.
					</TypographyError>
					: (fileSelectedFlag
						? <TypographySuccess>
							File selected: {value[0]['name']}
						</TypographySuccess>
						: <Typography>
							Uploaded file will be processed and displayed as profile information
						</Typography>)}
			</PaperDashed>
			<Typography className="paper-cvbuilder_typography-or">
				or
			</Typography>
			<ButtonPrimary to={nextPageUrl}>
				Build HAPP CV
			</ButtonPrimary>
		</React.Fragment>}
	</StyledWrapper>;
};

UploadCv = React.memo(UploadCv);
UploadCv.defaultProps = {
	onSubmit: () => {},
};
UploadCv.propTypes = {
	onSubmit: PropTypes.func,
};

export default UploadCv;
