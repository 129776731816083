import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';

let Cookie = (props) => {
	return <StyledWrapper { ...props }>
		Cookie page	
	</StyledWrapper>;
};

Cookie = React.memo(Cookie);
Cookie.defaultProps = {
};
Cookie.propTypes = {
};

export default Cookie;
