import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import Field from '@nest-datum-ui/Field';
import ButtonPrimary from 'components/Button/Primary';
import InputPhone from 'components/Input/Phone';
import StyledWrapper from './Styled/Wrapper.jsx';
import handlerSubmit from './handler/submit.js';
import { ReactComponent as ArrowDown } from 'static/svg/icons/arrowDown-small-light.svg';

/**
 * https://i.imgur.com/JCnVhKO.png
 */
let Phone = ({ onSubmit, ...props }) => {
	const routeName = React.useContext(ContextRoute);
	const { [routeName]: { form: { storeName, id, apiUrl, nextPageUrl } } } = React.useContext(ContextProps);
	const onSubmitWrapper = React.useCallback((e) => {
		handlerSubmit(e, storeName, apiUrl, nextPageUrl);
		onSubmit(e);
	}, [
		storeName,
		apiUrl,
		nextPageUrl,
		onSubmit,
	]);

	useEffect(() => {
		window.history.pushState("","","")
	})

	return <StyledWrapper 
		{ ...props }
		storeName={storeName} 
		id={id} 
		onSubmit={onSubmitWrapper}>
		<Field
			Component={(props) => <InputPhone { ...props } storeName={id} />}
			countryProps={{ IconComponent: () => <ArrowDown /> }}
			form={id}
			name="happ-sso-user-option-phone" />
		<ButtonPrimary 
			type="submit" 
			form={id}>
			Continue
		</ButtonPrimary>
	</StyledWrapper>;
};

Phone = React.memo(Phone);
Phone.defaultProps = {
	onSubmit: () => {},
};
Phone.propTypes = {
	onSubmit: PropTypes.func,
};

export default Phone;
