import styled from 'styled-components';
import Grid from '@mui/material/Grid';

const UserMenuGrid = styled(Grid)`
    display: flex;
    justify-content: flex-end;
    flex: 1;
    gap: 24px;
    align-items: center;
`;

export default UserMenuGrid;