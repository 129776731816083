
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Settings',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 9999,
	headerTabMenuTitle: 'Settings',

	pageUrl: 'settings',
	pageTitle: 'Settings',
};

export default config;
