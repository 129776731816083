
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Manager',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 0,
	headerTabMenuTitle: 'Manager',

	pageUrl: 'manager',
	pageTitle: 'Manager',
};

export default config;
