import styled from 'styled-components';
import Grid from '@mui/material/Grid';

const Wrapper = styled(Grid)`
    &.paper-grid_faq-box .MuiGrid-item a{
        border-radius: 16px!important;
    }

    @media (max-width: 600px) {
		padding: 0px 40px;

        &.paper-grid_faq-box .MuiGrid-item a{
            max-height: 150px;
        }
	}
`;

export default Wrapper;