import styled from 'styled-components';

const Wrapper = styled.div`
    && .MuiGrid-container .MuiGrid-item{
        flex-direction: row;
    }
    && .MuiGrid-container > .MuiGrid-item:nth-child(1){
        flex: 11;
        flex-direction: column;
        justify-content: flex-start!important;
        align-items: flex-start;
        gap: 4px;

        p:nth-child(1){
            font-weight: 500!important;
            font-size: 16px!important;
            line-height: 23px!important;
            color: #0A0A0A!important;
        }
        p:nth-child(2){
            font-weight: 500!important;
            font-size: 14px!important;
            line-height: 20px!important;
            color: #0A0A0A!important;
        }
        p:nth-child(3){
            font-weight: 400!important;
            font-size: 12px!important;
            line-height: 17px!important;
            color: #0A0A0A!important;
        }
        p:nth-child(4){
            font-weight: 400!important;
            font-size: 12px!important;
            line-height: 17px!important;
            color: #0A0A0A!important;
        }
    }
    && .MuiGrid-container > .MuiGrid-item:nth-child(2){
        flex: 1;
        gap: 16px;
        flex-direction: column;
        justify-content: center!important;
        align-items: flex-end;
    }
    && .MuiGrid-container{
        display: flex;
    }

    && button{
        padding: 0px;
    }
    && .MuiGrid-container > .MuiGrid-item p.paper-profile_btn-read-more{
        color: #0A0A0A!important;
        font-weight: 500!important;
        font-size: 12px!important;
        line-height: 17px!important;
        text-decoration-line: underline;
        cursor: pointer;
    }
`;

export default Wrapper;