import styled from 'styled-components';
import Box from '@mui/material/Box';

const Wrapper = styled(Box)`
    @media (max-width: 600px) {
        padding-left: 34px;
        padding-right: 34px;
        box-sizing: border-box;
		
		&& .paper-dashboard_container {
            flex-direction: column;
        }
        && .paper-dashboard_container .paper-dashboard_item-aside .MuiGrid-item:first-child{
            width: 91px;
        }
        && .paper-dashboard_container .MuiGrid-container{
            gap: 12px;
        }

        && .MuiGrid-item:nth-child(1) .MuiPaper-root{
            margin-bottom: 0px;
            padding: 13px;
        }
        
        && .MuiGrid-item{
            padding-top: 16px;
        }

        && .MuiGrid-container .MuiGrid-container .MuiGrid-item{
            padding-top: 0px;
        }
        && .MuiGrid-container .MuiGrid-container .MuiGrid-item:nth-child(2){
            flex: 1;
        }
    }
`;

export default Wrapper;