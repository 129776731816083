import React from 'react';
import { useSelector } from 'react-redux';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import { ContextList } from '@nest-datum-ui/Context';
import Grid from '@mui/material/Grid';
import TypographySubtitlePrimary from 'components/Typography/Subtitle/Primary';
import TypographyBody from 'components/Typography/Body';
import StyledWrapper from './Styled/Wrapper.jsx';

let Item = (props) => {
	const { 
		name,
		salary,
		company: {
			name: companyName,
		}, 
	} = React.useContext(ContextList);
	const location = useSelector(selectorMainExtract([ 'api', 'form', 'get-started-job-location', 'jobLocation' ]));

	return <StyledWrapper { ...props }>
		<Grid container>
			<Grid 
				item
				xs={false}>
				<TypographySubtitlePrimary>
					Company
				</TypographySubtitlePrimary>
				<TypographyBody>
					{companyName}
				</TypographyBody>
			</Grid>
			<Grid 
				item
				xs={false}>
				<TypographySubtitlePrimary>
					Title
				</TypographySubtitlePrimary>
				<TypographyBody>
					{name}
				</TypographyBody>
			</Grid>
			<Grid 
				item
				xs={false}>
				<TypographySubtitlePrimary>
					Salary
				</TypographySubtitlePrimary>
				<TypographyBody>
					{salary} $/year
				</TypographyBody>
			</Grid>
			<Grid 
				item
				xs={false}>
				<TypographySubtitlePrimary>
					Location
				</TypographySubtitlePrimary>
				<TypographyBody>
					{location}
				</TypographyBody>
			</Grid>
		</Grid>
	</StyledWrapper>;
};

Item = React.memo(Item);
Item.defaultProps = {
};
Item.propTypes = {
};

export default Item;
