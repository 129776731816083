import obj from './index.js';

const dateRange = (value) => {
	if (!obj(value)) {
		return false;
	}

	const startDate = Number(value['start'][0]+value['start'][1])
	if(!startDate) {
		return false;
	}
	if (value['start'] && value['start'].length === 6
		&& value['progress'] && value['progress'] === true) {
		return true;
	}

	const endDate = Number(value['end'][0]+value['end'][1])
	if(!endDate) {
		return false;
	}
	if (value['start'] && value['start'].length === 6
		&& value['end'] && value['end'].length === 6 
		&& value['end'] >= value['start']) {
		return true;
	}
	return false;
};

export default dateRange;
