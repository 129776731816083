import React from 'react';
import { useSelector } from 'react-redux';
import { ContextRoute } from '@nest-datum-ui/Context';
import {
	selectorMainExtract,
	actionApiListGet,
	actionApiListPurge,
} from '@nest-datum-ui/Store';
import PaperPreviewExperience from 'components/Paper/Preview/Experience';
import PaperPreviewEducation from 'components/Paper/Preview/Education';
import PaperPreviewCertificates from 'components/Paper/Preview/Certificates';
import PaperPreviewSkills from 'components/Paper/Preview/Skills';
import PaperPreviewLanguages from 'components/Paper/Preview/Languages';

let Experience = () => {
	const userId = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'id' ]));

	React.useEffect(() => {
		if (userId) {
			actionApiListGet(`userFormContent`, {
				apiUrl: `${process.env.URL_API_FORMS}/content`,
				page: 1,
				limit: 9999,
				relations: {
					fieldContents: true,
				},
				filter: {
					userId,
				},
			})();
		}
	}, [
		userId,
	]);

	React.useEffect(() => () => {
		actionApiListPurge(`userFormContent`)();
	}, [
	]);

	return <ContextRoute.Provider value="authedProfileExperience">
		<PaperPreviewExperience />
		<PaperPreviewEducation />
		<PaperPreviewCertificates />
		<PaperPreviewSkills />
		<PaperPreviewLanguages />
	</ContextRoute.Provider>;
};

Experience = React.memo(Experience);
Experience.defaultProps = {
};
Experience.propTypes = {
};

export default Experience;
