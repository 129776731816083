import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let Text = (props) => {
	return <StyledWrapper { ...props } error={props.error && <ErrorText title={props.error} />}/>;
};

Text = React.memo(Text);
Text.defaultProps = {
};
Text.propTypes = {
};

export default Text;
