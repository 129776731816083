import styled from 'styled-components';
import Paper from '../../Paper.jsx';

const Wrapper = styled(Paper)`
    padding: 24px 36px 26px 36px;
    border-radius: 16px!important;

    && p {
        margin-bottom: 24px;
    }

    @media (max-width: 600px) {
        box-shadow: none!important;
        padding: 24px 43px 26px 43px;

        && p {
            margin-bottom: 40px;
        }
	}
`;

export default Wrapper;