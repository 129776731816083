import React from 'react';
import Typography from 'components/Typography';
import StyledWrapper from './Styled/Wrapper.jsx';
import PaperPayItem from 'components/Paper/Preview/ItemJob/Item';

let data = [
	{ title: "128 000$" },
	{ title: "Per year" }
]

let Pay = (props) => {
	return <StyledWrapper { ...props } title="Desirable pay" to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}/${process.env.ROUTE_PAY}`} isDataAdded={data.length !== 0 ? 1: 0}>
		{ data.length === 0 ? <Typography className="message-title_box">
			Add your desirable pay
		</Typography>: data.map((item) => { return <PaperPayItem key={Math.random()} title={item.title} /> })}
	</StyledWrapper>;
};

Pay = React.memo(Pay);
Pay.defaultProps = {
	isDataAdded: false
};
Pay.propTypes = {
};

export default Pay;
