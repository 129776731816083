import React from 'react';
import ButtonSecondary from 'components/Button/Secondary';
import StyledWrapper from './Styled/Wrapper.jsx';

let Buttons = (props) => {
	return <StyledWrapper { ...props }>
        <ButtonSecondary>
            Review CV
        </ButtonSecondary>
        <ButtonSecondary to={`/${process.env.ROUTE_AUTHED}/cv-builder`}>
            Upload CV
        </ButtonSecondary>
        <ButtonSecondary>
            Download CV
        </ButtonSecondary>
	</StyledWrapper>;
};

Buttons = React.memo(Buttons);
Buttons.defaultProps = {
};
Buttons.propTypes = {
};

export default Buttons;
