import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ContextProps } from '@nest-datum-ui/Context';
import LayoutBase from '@nest-datum-ui/Layout';
import { strPlaceholder as utilsFormatStrPlaceholder } from '@nest-datum-utils/format';
import PaperHeader from 'components/Paper/Header';
import MenuHeader from 'components/Menu/Header';
import MenuMobile from 'components/Menu/Header/Mobile/Header';
import MenuMobilePopap from 'components/Menu/Header/Popap/Header';
import StyledWrapper from './Styled/Wrapper.jsx';
import StyledContentWrapper from './Styled/ContentWrapper.jsx';

const newWidthOfLayout = [
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_JOBS}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_JOBS}/:jobId`,
];

let Layout = () => {
	const { pathname } = useLocation();
	const urlParams = useParams();
	const routes = React.useContext(ContextProps);
	const cvBuilderRoutes = Object
		.keys(routes)
		.filter((key) => key.indexOf('authedCvBuilder') === 0 
			&& pathname.indexOf(((routes[key] || {})['form'] || {})['pageUrl']) === 0
			&& ((routes[key] || {})['form'] || {})['displaySteppers']);
	const cvBuilderRoutesFlag = cvBuilderRoutes.length > 0;
	const newLayoutWidth = (newWidthOfLayout.filter((item) => utilsFormatStrPlaceholder(item, urlParams) === pathname)).length > 0;
	
	routes['cvBuilderRoutesFlag'] = cvBuilderRoutesFlag;

	return <ContextProps.Provider value={{ ...routes }}>
		<StyledWrapper className={newLayoutWidth ? "paper-layout_jobs-page" : ""}>
			<PaperHeader cvBuilderRoutesFlag={cvBuilderRoutesFlag}>
				<MenuHeader />
				<MenuMobile />
				<MenuMobilePopap />
			</PaperHeader>
			<StyledContentWrapper>
				<LayoutBase />
			</StyledContentWrapper>
		</StyledWrapper>
	</ContextProps.Provider>;
};

Layout = React.memo(Layout);
Layout.defaultProps = {
};
Layout.propTypes = {
};

export default Layout;
