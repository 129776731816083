import React from 'react';
import { useSelector } from 'react-redux';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import { arrToObj as formatArrToObj } from '@nest-datum-utils/format';
import Grid from '@mui/material/Grid';
import Typography from 'components/Typography';
import TypographyBody from 'components/Typography/Body';
import IconButtonEditPrimary from 'components/IconButton/Edit/Primary';
import IconButtonEditFacebook from 'components/IconButton/Edit/Facebook';
import IconButtonEditLinkedin from 'components/IconButton/Edit/Linkedin';
import Min from './Min';
import StyledWrapper from './Styled/Wrapper.jsx';

let Profile = (props) => {
	const email = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'email' ]));
	const userUserOptions = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'userUserOptions' ])) || [];
	const experienceTitle = useSelector(selectorMainExtract([ 'api', 'list', 'experienceTitle', 'data', 0, 'value' ]));
	const userUserOptionsObj = formatArrToObj(userUserOptions, 'userOptionId');

	return <StyledWrapper { ...props }>
		<Grid container className='paper-profile_min-edit-line'>
			<Grid item xs={true}>
				<Min />
			</Grid>
			<Grid item xs={false}>
				<IconButtonEditPrimary to={`${window.location.pathname}/${process.env.ROUTE_EDIT}`} />
			</Grid>
		</Grid>
		<Grid container className='paper-profile_info-box'>
			<Grid item md={6}>
				<Typography className="paper-profile_info-title">
					Position
				</Typography>
				<TypographyBody className="paper-profile_info-subtitle">
					{String(userUserOptionsObj['happ-sso-user-option-position']['content'] || experienceTitle)}
				</TypographyBody>
				{userUserOptionsObj['happ-sso-user-option-location']['content']
					&& <React.Fragment>
						<Typography className="paper-profile_info-title">
							Location
						</Typography>
						<TypographyBody className="paper-profile_info-subtitle">
							{String(userUserOptionsObj['happ-sso-user-option-location']['content'])}
						</TypographyBody>
					</React.Fragment>}
				<Typography className="paper-profile_info-title">
					Phone
				</Typography>
				<TypographyBody className="paper-profile_info-subtitle">
					{String(userUserOptionsObj['happ-sso-user-option-phone']['content'])}
				</TypographyBody>
			</Grid>
			<Grid item md={6}>
				<Typography className="paper-profile_info-title">
					Email (verified)
				</Typography>
				<TypographyBody className="paper-profile_info-subtitle">
					{String(email)}
				</TypographyBody>
				{userUserOptionsObj['happ-sso-user-option-site']['content']
					&& <React.Fragment>
						<Typography className="paper-profile_info-title">
							Personal website
						</Typography>
						<TypographyBody className="paper-profile_info-subtitle">
							{String(userUserOptionsObj['happ-sso-user-option-site']['content'])}
						</TypographyBody>
					</React.Fragment>}
				{(userUserOptionsObj['happ-sso-user-option-linkedin']['content']
					|| userUserOptionsObj['happ-sso-user-option-facebook']['content'])
					&& <React.Fragment>
						<Typography className="paper-profile_info-title">
							Social Media
						</Typography>
						<Grid container alignItems="center" spacing={2}>
							{userUserOptionsObj['happ-sso-user-option-linkedin']['content']
								&& <Grid item xs={false}>
									<IconButtonEditFacebook />
								</Grid>}
							{userUserOptionsObj['happ-sso-user-option-facebook']['content']
								&& <Grid item xs={false}>
									<IconButtonEditLinkedin />
								</Grid>}
						</Grid>
					</React.Fragment>}
			</Grid>
		</Grid>
	</StyledWrapper>;
};

Profile = React.memo(Profile);
Profile.defaultProps = {
};
Profile.propTypes = {
};

export default Profile;
