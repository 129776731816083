
const config = {
	form: {
		storeName: 'authed-job-privacy',
		id: 'authed-job-privacy',
		apiUrl: process.env.URL_API_SUPPORT,
		pageUrl: `/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_SUPPORT}/${process.env.ROUTE_PRIVACY}`,
	},
};

export default config;
