import axios from 'axios';
import Store, {
	hookUrlNavigate,
	actionApiFormProp,
	actionApiFormPurge,
} from '@nest-datum-ui/Store';
import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import {
	arrToObj as formatArrToObj,
	urlApiStr as utilsFormatUrlApiStr,
	httpErrorMessage as utilsFormatHttpErrorMessage,
} from '@nest-datum-utils/format';
import { strFilled as utilsCheckStrFilled } from '@nest-datum-utils/check';

const submit = async (e, storeName, apiUrl, nextPageUrl) => {
	const countriesList = (Store()
		.getState()
		.api
		.list['countries'] || {})
		.data || [];
	const userData = Store()
		.getState()
		.api
		.form['sso-sign-in'] || {};
	const {
		'happ-sso-user-option-phone_additionalValue': phoneId,
		'happ-sso-user-option-phone': phone,
	} = Store()
			.getState()
			.api
			.form[storeName] || {};
	const options = formatArrToObj(
		(countriesList.find((item) => item['id'] === phoneId) || {})['regionContents'] || [],
		'typeOptionId',
	);
	const phoneCode = '+' + ((options || {})['happ-countries-type-option-code'] || {})['value'];
	const phoneLength = options['happ-countries-type-option-length']
		? Number(options['happ-countries-type-option-length']['value'])
		: 10;
	const phoneProcessed = phoneCode + phone;
	const validatedData = await utilsValidateStore(storeName, {
		'happ-sso-user-option-phone': [
			{
				text: 'Wrong format of phone.',
				check: [
					(value, fieldName) => phoneProcessed.indexOf(phoneCode) === 0,
					(value, fieldName, a, b) =>
						phoneProcessed.length === phoneLength + phoneCode.length,
				],
				isRequired: true,
			},
			{
				text: 'Required field. Fill in your phone number',
				check: [utilsCheckStrFilled],
				isRequired: true,
			},
		],
	});

	if (validatedData) {
		actionApiFormProp(
			storeName,
			'loader',
			true,
		)(async () => {
			try {
				const userUserOptionsObj = formatArrToObj(
					userData['userUserOptions'],
					'userOptionId',
				);

				await axios.patch(
					utilsFormatUrlApiStr(
						`${apiUrl}/${userUserOptionsObj['happ-sso-user-option-phone']['id']}/option`,
					),
					{
						content: phoneProcessed,
					},
				);

				setTimeout(() => {
					actionApiFormPurge(storeName)();
				}, 0);
				hookUrlNavigate(nextPageUrl);
			} catch (err) {
				actionApiFormProp(storeName, 'loader', false)();

				throw new Error(utilsFormatHttpErrorMessage(err, apiUrl));
			}
		});
	}
};

export default submit;
