import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import configSignIn from '@nest-datum-ui-admin-lib/sso/src/config/signIn.js';
import ButtonLink from '@nest-datum-ui/Button/Link';
import ButtonHeader from 'components/Button/Header';
import StyledWrapper from './Styled/Wrapper.jsx';
import Grid from '@mui/material/Grid';

import { ReactComponent as SvgIconsHomeSmall } from 'static/svg/icons/dashboardMenu-small.svg';
import { ReactComponent as SvgIconsProfileSmall } from 'static/svg/icons/profileMenu-small.svg';
import { ReactComponent as SvgIconsJobsSmall } from 'static/svg/icons/jobsMenu-small.svg';
import { ReactComponent as SvgIconsOpenMenuSmall } from 'static/svg/icons/openMenu-small.svg';

let Header = ({ menuVisible, ...props }) => {
	const { pathname } = useLocation();
	const userId = useSelector(selectorMainExtract([ 'api', 'form', configSignIn.storeName, 'id' ]));
	
	return <StyledWrapper container>
		<Grid
			item
			xs={false}>
			<ButtonHeader
				component={ButtonLink}
				to={`/${process.env.ROUTE_AUTHED}`}>
				<SvgIconsHomeSmall />
				<div>Dashboard</div>
			</ButtonHeader>
		</Grid>
		<Grid
			item
			xs={false}>
			<ButtonHeader
				component={ButtonLink}
				to={`/${process.env.ROUTE_AUTHED}/${userId}`}
				active={pathname.indexOf(`/${process.env.ROUTE_AUTHED}/${userId}`) === 0}>
				<SvgIconsProfileSmall />
				<div>Profile</div>
			</ButtonHeader>
		</Grid>
		<Grid
			item
			xs={false}>
			<ButtonHeader
				component={ButtonLink}
				to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_JOBS}`}>
				<SvgIconsJobsSmall />
				<div>Jobs</div>
			</ButtonHeader>
		</Grid>
		<Grid
			item
			xs={false}>
			<ButtonHeader onClick={menuVisible}>
				<SvgIconsOpenMenuSmall />
				<div>Menu</div>
			</ButtonHeader>
		</Grid>
	</StyledWrapper>;
};

Header = React.memo(Header);
Header.defaultProps = {
};
Header.propTypes = {
};

export default Header;
