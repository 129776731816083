import React from 'react';
import { ContextRoute } from '@nest-datum-ui/Context';
import CookieDoc from 'components/Paper/Docs/Cookie';

let Cookie = () => {
	return <ContextRoute.Provider>
		<CookieDoc />
	</ContextRoute.Provider>;
};

Cookie = React.memo(Cookie);
Cookie.defaultProps = {
};
Cookie.propTypes = {
};

export default Cookie;
