import styled from 'styled-components';
import Paper from '../../../Paper.jsx';

const Wrapper = styled(Paper)`
    box-shadow: none!important;
    margin: 24px 34px;
    height: 48px;

    && .MuiGrid-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    && span.paper-logo_mobile .MuiTouchRipple-root{
        display: none;
    }
    && .paper-logo_mobile div{
        display: flex;
    }
    && .paper-logo_mobile{
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08)!important;
        border-radius: 16px!important;
        padding: 10px 6px!important;
        min-width: 48px;
    }

    && .paper-btn_got-hired{
        width: 125px;
        font-size: 12px!important;
        line-height: 17px!important;
    }

    @media (min-width: 601px) {
        display: none;
    }
`;

export default Wrapper