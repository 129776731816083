import axios from 'axios';
import { 
	hookUrlNavigate,
	actionApiFormProp,
	actionApiFormPurge,
} from '@nest-datum-ui/Store';
import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import { 
	arrFilled as utilsCheckArrFilled,
	strIdExists as utilsCheckStrIdExists, 
} from '@nest-datum-utils/check';
import { 
	urlApiStr as  utilsFormatUrlApiStr, 
	httpErrorMessage as utilsFormatHttpErrorMessage, 
} from '@nest-datum-utils/format';

const submit = async (e, storeName, apiUrl, nextPageUrl) => {
	const validatedData = await utilsValidateStore(storeName, {
		'happ-forms-field-desirable-workplace': {
			text: 'Required field. Fill in your job tittle',
			check: [ utilsCheckArrFilled ],
		},
		'happ-forms-field-desirable-worktype': {
			text: 'Required field. Fill in your company',
			check: [ utilsCheckArrFilled ],
		},
	});

	if (validatedData) {
		const validatedDataKeys = Object.keys(validatedData);

		if (validatedDataKeys.length > 0) {
			actionApiFormProp(storeName, 'loader', true)(async () => {
				try {
					const response = await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/content`), {
						formId: 'happ-forms-preferences',
						contentStatusId: 'happ-forms-content-status-active',
					});
					const id = ((response || {}).data || {}).id;

					if (!utilsCheckStrIdExists(id)) {
						throw new Error('Content ID is incorrect.');
					}
					setTimeout(async () => {
						let i = 0;

						while (i < validatedData['happ-forms-field-desirable-workplace'].length) {
							await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
								fieldId: 'happ-forms-field-desirable-workplace',
								value: validatedData['happ-forms-field-desirable-workplace'][i],
							});
							i++;
						}
						i = 0;

						while (i < validatedData['happ-forms-field-desirable-workplace'].length) {
							await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
								fieldId: 'happ-forms-field-desirable-worktype',
								value: validatedData['happ-forms-field-desirable-worktype'][i],
							});
							i++;
						}
						setTimeout(() => {
							actionApiFormPurge(storeName)();
						}, 0);
						hookUrlNavigate(nextPageUrl);
					}, 0);
				}
				catch (err) {
					actionApiFormProp(storeName, 'loader', false)();

					throw new Error(utilsFormatHttpErrorMessage(err, apiUrl));
				}
			});
		}
		else {
			actionApiFormPurge(storeName)();
			hookUrlNavigate(nextPageUrl);
		}
	}
};

export default submit;
