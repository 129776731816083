import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';

let Item = (props) => {
	const { title } = props;

	return <StyledWrapper { ...props }>
		{title}
	</StyledWrapper>;
};

Item = React.memo(Item);
Item.defaultProps = {
};
Item.propTypes = {
};

export default Item;
