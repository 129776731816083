import styled from 'styled-components';
import Box from '@mui/material/Box';

const Wrapper = styled(Box)`
    max-width: 1120px;
    min-height: 100vh;
    margin: auto;

    &.paper-layout_jobs-page{
        max-width: 1254px;
    }
`;

export default Wrapper;