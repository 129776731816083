import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import { arrToObj as formatArrToObj } from '@nest-datum-utils/format';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import Grid from '@mui/material/Grid';
import Field from '@nest-datum-ui/Field';
import ButtonSecondary from 'components/Button/Secondary';
import ButtonPrimary from 'components/Button/Primary';
import InputText from 'components/Input/Text';
import InputFullName from 'components/Input/FullName';
import InputPosition from 'components/Input/Position';
import InputLocation from 'components/Input/Location';
import InputPhone from 'components/Input/Phone';
import InputEmail from 'components/Input/Email';
import SelectCvStatus from 'components/Select/CvStatus';
import { ReactComponent as ArrowDown } from 'static/svg/icons/arrowDown-small-light.svg';
import StyledWrapper from './Styled/Wrapper.jsx';
import handlerSubmit from './handler/submit.js';

let Profile = ({ onSubmit, ...props }) => {
	const routeName = React.useContext(ContextRoute);
	const { 
		[routeName]: { 
			form: { 
				storeName, 
				id, 
				apiUrl, 
				cvStatus, 
			}, 
		}, 
	} = React.useContext(ContextProps);
	const { userId } = useParams();
	const onSubmitWrapper = React.useCallback((e) => {
		handlerSubmit(e, storeName, apiUrl, userId);
		onSubmit(e);
	}, [
		storeName,
		apiUrl,
		userId,
		onSubmit,
	]);
	const experienceTitle = useSelector(selectorMainExtract([ 'api', 'list', 'experienceTitle', 'data', 0, 'value' ]));
	const email = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'email' ]));
	const userUserOptions = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'userUserOptions' ])) || [];
	const userUserOptionsObj = formatArrToObj(userUserOptions, 'userOptionId');
	const fullNameDefault = `${(((userUserOptionsObj || {})['happ-sso-user-option-firstname'] || {})['content'] || '')} ${((userUserOptionsObj || {})['happ-sso-user-option-lastname'] || {})['content'] || ''}`;
	const positionDefault = ((userUserOptionsObj || {})['happ-sso-user-option-position'] || {})['content'] || experienceTitle || '';
	const locationDefault = ((userUserOptionsObj || {})['happ-sso-user-option-location'] || {})['content'] || '';
	const facebookDefault = ((userUserOptionsObj || {})['happ-sso-user-option-facebook'] || {})['content'] || '';
	const linkedinDefault = ((userUserOptionsObj || {})['happ-sso-user-option-linkedin'] || {})['content'] || '';
	const siteDefault = ((userUserOptionsObj || {})['happ-sso-user-option-site'] || {})['content'] || '';

	return <StyledWrapper { ...props }
		storeName={storeName} 
		id={id} 
		onSubmit={onSubmitWrapper}>
		<Field
			Component={InputFullName}
			form={id}
			name="fullName"
			placeholder="Full Name"
			defaultValue={fullNameDefault} />
		<Field
			Component={InputPosition}
			form={id}
			name="happ-sso-user-option-position"
			placeholder="Position"
			defaultValue={positionDefault} />
		<Field
			Component={InputLocation}
			form={id}
			name="happ-sso-user-option-location"
			placeholder="Location"
			defaultValue={locationDefault} />
		<Field
			Component={(props) => <InputPhone { ...props } storeName={id} />}
			countryProps={{ IconComponent: () => <ArrowDown /> }}
			form={id}
			name="happ-sso-user-option-phone" />
		<Field 
			Component={InputEmail}
			form={id}
			name="email"
			defaultValue={email} />
		<Field
			Component={InputText}
			form={id}
			name="happ-sso-user-option-facebook"
			placeholder="Facebook"
			defaultValue={facebookDefault} />
		<Field
			Component={InputText}
			form={id}
			name="happ-sso-user-option-linkedin"
			placeholder="Linkedin"
			defaultValue={linkedinDefault} />
		<Field
			Component={InputText}
			form={id}
			name="happ-sso-user-option-site"
			placeholder="Personal Website"
			defaultValue={siteDefault} />
		<Field
			storeName={cvStatus.storeName}
			apiUrl={cvStatus.apiUrl}
			Component={SelectCvStatus}
			form={id}
			name="cvStatusId" />
		<Grid container className="btns-form">
			<Grid
				item
				xs={false}>
				<ButtonSecondary to={`/${process.env.ROUTE_AUTHED}/${userId}`}>
					Cancel
				</ButtonSecondary>
			</Grid>
			<Grid
				item
				xs={false}>
				<ButtonPrimary 
					type="submit" 
					form={id}>
					Save
				</ButtonPrimary>
			</Grid>
		</Grid>
	</StyledWrapper>;
};

Profile = React.memo(Profile);
Profile.defaultProps = {
	onSubmit: () => {},
};
Profile.propTypes = {
	onSubmit: PropTypes.func,
};

export default Profile;
