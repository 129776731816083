
const reset = {
	pageTitle: 'Reset',
	pageUrl: 'reset',
			
	id: 'sso-reset',
	storeName: 'sso-reset',
	apiUrl: 'user/reset',
};

export default reset;
