
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Accesses',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 9990,
	headerTabMenuTitle: 'Accesses',

	pageUrl: 'accesses',
	pageTitle: 'Accesses',
};

export default config;
