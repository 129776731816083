
const config = {
	form: {
		storeName: 'authed-job',
		id: 'authed-job',
	},
	list: {
		storeName: 'authed-job',
		id: 'authed-job',
		apiUrl: process.env.URL_API_JOB,
	},
};

export default config;
