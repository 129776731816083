import axios from 'axios';
import Store, { 
	hookUrlNavigate,
	actionApiFormProp,
	actionApiFormPurge,
} from '@nest-datum-ui/Store';
import { 
	arrToObj as formatArrToObj,
	urlApiStr as  utilsFormatUrlApiStr, 
	httpErrorMessage as utilsFormatHttpErrorMessage, 
} from '@nest-datum-utils/format';
import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import { strFilled as utilsCheckStrFilled } from '@nest-datum-utils/check';
import { strTitle as utilsCheckStrTitle } from '@nest-datum-utils/check';

const submit = async (e, {
	storeName, 
	apiUrl, 
}) => {
	const userData = Store()
		.getState()
		.api
		.form['sso-sign-in'] || {};
		
	const validatedData = await utilsValidateStore(storeName, {
		jobLocation: [{
			text: 'Fill in your location correctly',
			check: [ utilsCheckStrTitle ],
			isRequired: true,
		},{
			text: 'Required field. Fill in your location',
			check: [ utilsCheckStrFilled ],
			isRequired: true,
		}],
	});
	
	// const jobKind = Store().getState().api.form['get-started-job-kind'];

	if (validatedData) {
		localStorage.setItem('jobLocation', validatedData['jobLocation']);
		
		actionApiFormProp(storeName, 'loader', true)(async () => {
			try {
				const userUserOptionsObj = formatArrToObj(userData['userUserOptions'], 'userOptionId');
				await axios.patch(utilsFormatUrlApiStr(`${apiUrl}/${userUserOptionsObj['happ-sso-user-option-location']['id']}/option`), {
					content: validatedData['jobLocation'],
				});

				setTimeout(() => {
					actionApiFormPurge(storeName)();
				}, 0);

				hookUrlNavigate("/app/cv-builder");
			}
			catch (err) {
				actionApiFormProp(storeName, 'loader', false)();

				throw new Error(utilsFormatHttpErrorMessage(err, apiUrl));
			}
		});

		// actionApiListGet(storeName, {
		// 	apiUrl: `${process.env.URL_API_COUNTRIES}/region`,
		// 	query: validatedData['jobLocation'],
		// })(({ data }) => {
		// 	actionApiListGet(quickOffersStoreName, { 
		// 		apiUrl: `${process.env.URL_API_JOBS}/post`,
		// 		page: 1,
		// 		limit: 3,
		// 		query: jobKind,
		// 		filter: {
		// 			isDeleted: false,
		// 			locationId: (data[0] || {})['id'],
		// 			company: {
		// 				isDeleted: false,
		// 			},
		// 		},
		// 	})();
		// 	hookUrlNavigate(nextPageUrl);
		// 	setTimeout(() => actionApiFormProp(storeName, 'loader', false)(), 0);
		// });
	}
};

export default submit;
