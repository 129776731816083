import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormVerify from '@nest-datum-ui-admin-lib/sso/src/components/Form/Verify';

let Verify = () => {
	return <Box>
		<Typography variant="h4">
			Account activation...
		</Typography>
		<FormVerify />
	</Box>;
};

Verify = React.memo(Verify);
Verify.defaultProps = {
};
Verify.propTypes = {
};

export default Verify;
