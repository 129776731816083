import str from './index.js';

const password = (value) => {
	return str(value) 
		&& value[0] !== ' '
		&& value[value.length - 1] !== ' '
		&& value.length >= 8 
		&& value.length <= 25
		&& /\d/.test(value)
		&& /\p{L}/u.test(value)
		&& !/[^a-z0-9A-Z]/u.test(value);
};

export default password;
