import React from 'react';
import PaperQuickOffersItem from 'components/Paper/QuickOffers/Item';
import StyledWrapper from './Styled/Wrapper.jsx';

let QuickOffers = (props) => {
	const initialFilter = React.useMemo(() => ({
		company: {
			isDeleted: false,
		},
	}), [
	]);

	return <StyledWrapper 
		{ ...props } 
		initialFilter={initialFilter}
		loadOnFirstRender
		mobileWithDots>
		<PaperQuickOffersItem />
	</StyledWrapper>;
};

QuickOffers = React.memo(QuickOffers);
QuickOffers.defaultProps = {
};
QuickOffers.propTypes = {
};

export default QuickOffers;
