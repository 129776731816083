import styled from 'styled-components';
import Typography from '../../../Typography.jsx';

const Wrapper = styled(Typography)`
    color: #616161;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 20px!important;
`;

export default Wrapper;