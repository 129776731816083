import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import configSignIn from '@nest-datum-ui-admin-lib/sso/src/config/signIn.js';
import Grid from '@mui/material/Grid';
import AvatarHeader from 'components/Avatar/Header';
import IconButtonLogo from 'components/IconButton/Logo';
import IconButtonLogoMobile from 'components/IconButton/Logo/Mobile';
import IconButtonNotice from 'components/IconButton/Notice';
import IconButtonSetting from 'components/IconButton/Setting';
import ButtonHeaderLink from 'components/Button/Header/Link';
import StyledWrapperMobile from './Styled/Mobile/Wrapper.jsx';
import StyledWrapperMobileMenu from './Styled/Mobile/Menu.jsx';
import StyledGridPopapMenu from './Styled/Mobile/Popap.jsx';
import StyledWrapperDesktop from './Styled/Desktop/Wrapper.jsx';
import StyledGrid from './Styled/Desktop/Grid.jsx';
import LogoGrid from './Styled/Desktop/LogoGrid.jsx';
import MenuGrid from './Styled/Desktop/MenuGrid.jsx';
import UserMenuGrid from './Styled/Desktop/UserMenuGrid.jsx';
import ButtonHeaderPrimary from 'components/Button/Header/Primary';
import { strPlaceholder as utilsFormatStrPlaceholder } from '@nest-datum-utils/format';

const hiddenHeaderAndMobileMenu = [
	`/${process.env.ROUTE_VERIFY_MESSAGE}`,
	`/${process.env.ROUTE_SiGN_UP}`,
	`/${process.env.ROUTE_SiGN_IN}`,
	`/${process.env.ROUTE_RECOVERY}`,
	`/${process.env.ROUTE_GET_STARTED}`,
	`/${process.env.ROUTE_GET_STARTED}/${process.env.ROUTE_LOCATION}`,
	`/${process.env.ROUTE_GET_STARTED}/${process.env.ROUTE_QUICK_OFFERS}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}`,
	`/${process.env.ROUTE_AUTHED}/:userId/got-hired`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_PHONE}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_EXPERIENCE}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_EDUCATION}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_SKILLS}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_DESIRABLE_WORKPLACE}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_LANGUAGE}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_CV_BUILDER_COMPLETE}`,
];

const hiddenOnlyHeader = [
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_PAY}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_SCHEDULE}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_REMOTE}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_JOB_TYPE}`,
	`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}/${process.env.ROUTE_RELOCATE}`,
];

let Header = ({ cvBuilderRoutesFlag, children, storeName, ...props }) => {
	const { pathname } = useLocation();
	const urlParams = useParams();
	const [ visibleMenu, setVisibleMenu ] = React.useState(() => false);
	const authFlag = useSelector(selectorMainExtract([ 'api', 'form', configSignIn.storeName, 'authFlag' ]));
	const userId = useSelector(selectorMainExtract([ 'api', 'form', configSignIn.storeName, 'id' ]));
	const hideManagerSection = pathname.indexOf(`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_BUILDER}`) === 0;
	const hideSignInBtn = pathname.indexOf(`/${process.env.ROUTE_SiGN_IN}`) === 0 || pathname.indexOf(`/${process.env.ROUTE_RECOVERY}`) === 0 || pathname.indexOf(`/${process.env.ROUTE_SiGN_UP}`) === 0 || pathname.indexOf(`/${process.env.ROUTE_VERIFY_MESSAGE}`) === 0;
	const isAllHidden = (hiddenHeaderAndMobileMenu.filter((item) => utilsFormatStrPlaceholder(item, urlParams) === pathname)).length > 0;
	const isHeaderHidden = (hiddenOnlyHeader.filter((item) => utilsFormatStrPlaceholder(item, urlParams) === pathname)).length > 0;
	const isGotHiredHidden = utilsFormatStrPlaceholder(`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_JOBS}`, urlParams) === pathname;
	const menuVisible = React.useCallback((e) => setVisibleMenu((currentState) => !currentState), [
		setVisibleMenu,
	]);

	return <React.Fragment>
		<StyledWrapperDesktop>
			<StyledGrid container>
				<LogoGrid item>
					<IconButtonLogo />
				</LogoGrid>
				{(!hideManagerSection && !cvBuilderRoutesFlag && !isAllHidden) && (authFlag
					? <React.Fragment>
						<MenuGrid item>
							{children[0]}
						</MenuGrid>
						<UserMenuGrid item>
							<UserMenuGrid container className="paper-header__manage-section authed">
								<Grid item>
									<IconButtonNotice />
								</Grid>
								<Grid item>
									<AvatarHeader />
								</Grid>
								<Grid item>
									<IconButtonSetting />
								</Grid>
							</UserMenuGrid>
						</UserMenuGrid>
					</React.Fragment>
					: <UserMenuGrid item>
						<UserMenuGrid container className="paper-header__manage-section no-authed">
							<Grid item>
								{!hideSignInBtn && <ButtonHeaderLink to={`/${process.env.ROUTE_SiGN_IN}`}>
									Sign In
								</ButtonHeaderLink>}
							</Grid>
						</UserMenuGrid>
					</UserMenuGrid>)}
			</StyledGrid>
		</StyledWrapperDesktop>
		{ isAllHidden === false &&
		<>
			{ isHeaderHidden === false &&
			<StyledWrapperMobile>
				<Grid container>
					<Grid item>
						<IconButtonLogoMobile className="paper-logo_mobile" to={`/${process.env.ROUTE_AUTHED}`}/>
					</Grid>
					{ !isGotHiredHidden &&
						<Grid item>
							<ButtonHeaderPrimary to={`/${process.env.ROUTE_AUTHED}/${userId}/${process.env.ROUTE_GOT_HIRED}`} className="paper-btn_got-hired">
								Got Hired
							</ButtonHeaderPrimary>
						</Grid>
					}
				</Grid>
			</StyledWrapperMobile>
			}
			{ visibleMenu !== true && <StyledWrapperMobileMenu>
				<Grid container>
					{React.cloneElement(children[1], { menuVisible })}
				</Grid>
			</StyledWrapperMobileMenu> }

			<StyledGridPopapMenu className={ visibleMenu === true ? "paper-popap-mobile_visible" : "paper-popap-mobile_hidden" }>
				<IconButtonLogo className="paper-logo_mobile-popap" />
				{React.cloneElement(children[2], { menuVisible })}
			</StyledGridPopapMenu> 
		</>
		}
	</React.Fragment>;
};

Header = React.memo(Header);
Header.defaultProps = {
	storeName: 'form-sign-in'
};
Header.propTypes = {
};

export default Header;
