
const recovery = {
	pageTitle: 'Access recovery',
	pageUrl: 'recovery',
			
	id: 'sso-recovery',
	storeName: 'sso-recovery',
	apiUrl: 'user/recovery',
};

export default recovery;
