
const roleOption = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Options',

	pageUrl: 'roles/options',
	pageTitle: 'Options',

	fieldsBlock: true,
	fieldsBlockTitle: 'Options:',
};

export default roleOption;
