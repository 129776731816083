import React from 'react';
import PropTypes from 'prop-types';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let Position = (props) => {
	return <StyledWrapper { ...props } error={props.error && <ErrorText title={props.error} />} />;
};

Position = React.memo(Position);
Position.defaultProps = {
	placeholder: 'Position',
	name: 'position',
};
Position.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
};

export default Position;
