import React from 'react';
import Typography from 'components/Typography';
import StyledWrapper from './Styled/Wrapper.jsx';
import PaperPayItem from 'components/Paper/Preview/ItemJob/Item';

let data = [
	{ title: "4 hours" },
	{ title: "8 hours" },
	{ title: "12 hours" },
	{ title: "Day shifts" },
	{ title: "Night shifts" },
]

let Schedule = (props) => {
	return <StyledWrapper { ...props } title="Work Schedule" to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}/${process.env.ROUTE_SCHEDULE}`} isDataAdded={data.length !== 0 ? 1: 0}>
		{ data.length === 0 ? <Typography className="message-title_box">
		Add your desirable work schedule
		</Typography>: data.map((item) => { return <PaperPayItem key={Math.random()} title={item.title} /> })}
	</StyledWrapper>;
};

Schedule = React.memo(Schedule);
Schedule.defaultProps = {
	isDataAdded: false
};
Schedule.propTypes = {
};

export default Schedule;
