import axios from 'axios';
import { 
	hookUrlNavigate,
	actionApiFormProp,
	actionApiFormPurge,
} from '@nest-datum-ui/Store';
import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import { 
	str as utilsCheckStr,
	strFilled as utilsCheckStrFilled,
	strIdExists as utilsCheckStrIdExists,
	bool as utilsCheckBool,
	objDateRange as utilsCheckObjDateRange, 
} from '@nest-datum-utils/check';
import { 
	urlApiStr as  utilsFormatUrlApiStr, 
	httpErrorMessage as utilsFormatHttpErrorMessage, 
} from '@nest-datum-utils/format';

const submit = async (e, storeName, apiUrl, nextPageUrl, isMulti = false) => {
	let validatedData
	const validatedDataFirst = await utilsValidateStore(storeName, {
		'happ-forms-field-dont-have-education': {
			check: [ utilsCheckBool ]
		}
	});

	if(validatedDataFirst && validatedDataFirst['happ-forms-field-dont-have-education']) {
		validatedData = validatedDataFirst
	} else {
		validatedData = await utilsValidateStore(storeName, {
			'happ-forms-field-education-degree': {
				text: 'Required field. Fill in degree',
				check: [ utilsCheckStrFilled ],
				isRequired: true,
			},
			'happ-forms-field-education-stady': {
				text: 'Required field. Fill in your education stady',
				check: [ utilsCheckStrFilled ],
			},
			'happ-forms-field-education-school': {
				text: 'Required field. Fill in your school',
				check: [ utilsCheckStrFilled ],
				isRequired: true,
			},
			'happ-forms-field-education-date': {
				text: 'Required field. Fill in date field',
				check: [ utilsCheckObjDateRange ],
			},
			'happ-forms-field-in-progress': {
				text: 'Invalid format',
				check: [ utilsCheckBool ],
			},
			'happ-forms-field-achievemets': {
				text: 'Invalid format',
				check: [ utilsCheckStr ],
			},
		});
	}
	
	if (validatedData) {
		actionApiFormProp(storeName, 'loader', true)(async () => {
			try {
				const response = await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/content`), {
					formId: 'happ-forms-education',
					contentStatusId: 'happ-forms-content-status-active',
				});
				const id = ((response || {}).data || {}).id;

				if (!utilsCheckStrIdExists(id)) {
					throw new Error('Content ID is incorrect.');
				}
				setTimeout(async () => {
					if(validatedData && validatedData['happ-forms-field-dont-have-education'] === true) {
						await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
							fieldId: 'happ-forms-field-dont-have-education',
							value: true,
						});
					} else {
						await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
							fieldId: 'happ-forms-field-education-degree',
							value: validatedData['happ-forms-field-education-degree'],
						});
						await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
							fieldId: 'happ-forms-field-education-stady',
							value: String(validatedData['happ-forms-field-education-stady'] ?? ""),
						});
						await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
							fieldId: 'happ-forms-field-education-school',
							value: validatedData['happ-forms-field-education-school'],
						});
						await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
							fieldId: 'happ-forms-field-education-date',
							value: JSON.stringify(validatedData['happ-forms-field-education-date']),
						});
						await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
							fieldId: 'happ-forms-field-in-progress',
							value: Boolean(validatedData['happ-forms-field-in-progress']),
						});
						await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
							fieldId: 'happ-forms-field-achievemets',
							value: String(validatedData['happ-forms-field-achievemets'] ?? ''),
						});
					}

					setTimeout(() => {
						actionApiFormPurge(storeName)();
					}, 0);

					if (!isMulti) {
						hookUrlNavigate(nextPageUrl);
					}
				}, 0);
			}
			catch (err) {
				actionApiFormProp(storeName, 'loader', false)();

				throw new Error(utilsFormatHttpErrorMessage(err, apiUrl));
			}
		});
	}
};

export default submit;
