import styled from 'styled-components';
import List from '../../List.jsx';

const Wrapper = styled(List)`
	& .listBoxScroll > .MuiGrid-root {
		display: flex;
		flex-wrap: wrap-reverse;

		& > .MuiBox-root {
			min-width: 50%;
			max-width: 50%;
		}
	}

	@media (max-width: 600px) {
		padding: 0px 33px;
        & .listBoxScroll > .MuiGrid-root {
			& > .MuiBox-root {
				max-width: 100%;
				width: 100%;
			}
		}
    }
`;

export default Wrapper;