import styled from 'styled-components';
import Form from '../../Form.jsx';

const Wrapper = styled(Form)`
	&.disable-styles {
		& > .form-children__wrapper {
			& > label {
				min-width: 0px;
				min-height: 0px;
				padding: 0px;
				margin: 0px;
				display: block;
				border-radius: 0px;

				& > .MuiBox-root {
					&:after {
						display: none;
					}

					& > .MuiButtonBase-root {
						padding: 0px;
						margin: 0px;
						min-width: 0px;
						min-height: 0px;
					}

					& .image_profile-no-avatar {
						display: none;
					}
				}
			}
		}
	}
	&.with-styles {
		background: #D9D9D9;
		border-radius: 16px;
		min-height: 200px;
		margin-bottom: 16px;

		&& .MuiButtonBase-root{
			padding: 0px;
			width: 100%;
			height: 100%;
		}

		&& .form-children__wrapper label,
		&& .form-children__wrapper{
			height: 100%;
			border-radius: 16px;
			overflow: hidden;
		}
		&& .form-children__wrapper label > .MuiBox-root{
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		@media (max-width: 600px) {
			height: 91px;
			min-height: 91px;
			border-radius: 10px;
		}
	}
`;

export default Wrapper;