import React from 'react';
import { useSelector } from 'react-redux';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import { arrFilled as utilsCheckArrFilled } from '@nest-datum-utils/check';
import Typography from 'components/Typography';
import StyledWrapper from './Styled/Wrapper.jsx';
import PaperPayItem from 'components/Paper/Preview/ItemJob/Item';

let Skills = (props) => {
	const data = useSelector(selectorMainExtract([ 'api', 'list', 'userFormContent', 'data' ])) || [];
	const isDataAdded = data.find((content) => (content.formId === 'happ-forms-skills') && (content.fieldContents.length > 0));

	return <StyledWrapper 
		{ ...props } 
		title="Skills" 
		to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}/${process.env.ROUTE_SKILLS}`} 
		isDataAdded={isDataAdded 
			? 1
			: 0}>
		{(!utilsCheckArrFilled(data) || !isDataAdded)
			? <Typography className="message-title_box">
				Add your core skills
			</Typography>
			: data
				.filter((content) => (content.formId === 'happ-forms-skills'))
				.map((content) => content['fieldContents'].map((item) => <PaperPayItem 
					key={item['id']} 
					title={item['value']} />))}
	</StyledWrapper>;
};

Skills = React.memo(Skills);
Skills.defaultProps = {
	isDataAdded: false
};
Skills.propTypes = {
};

export default Skills;
