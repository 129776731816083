import styled from 'styled-components';
import Paper from '../../../Paper.jsx';

const Wrapper = styled(Paper)`
	&.MuiButtonBase-root {
		text-transform: inherit !important;
		padding: 0px !important;
		margin: 0px !important;
		justify-content: flex-start !important;
	}

	&& .MuiGrid-container span.MuiGrid-item:nth-child(2){
		padding-left: 12px;
	}

	&& .paper-job_item-title-company span,
	&& .paper-job_item-title-position span{
		padding-top: 0px;
		align-items: center;
		display: flex;
		justify-content: center;
	}
	&& .paper-job_item-rate-location p{
		margin-bottom: 5px;
	}
	&& .paper-job_item-rate-location span{
		padding-top: 0px;
	}
	&& .paper-job_item-title-position p{
		padding-bottom: 20px;
	}
	&& .paper-job_item-title-company p{
		padding-bottom: 18px;
		font-weight: 600;
		font-size: 18px;
		line-height: 26px;
	}
	&& .paper-job_item-rate-location{
		padding-bottom: 20px;
	}
	&& .paper-job_item-chip-interview{
		border-color: rgba(51, 187, 138, 1)!important;
	}
	&& .paper-job_item-chip-easy-apply{
		border-color: rgba(208, 51, 117, 1)!important;
	}
	&& .paper-job_item-chip-box{
		gap: 8px;
		padding-right: 8px;
	}
	&& .paper-job_item-recomendation > div svg{
		width: 20px;
		height: 20px;
		margin-left: 0px;
	}
	&& .paper-job_item-recomendation > div span{
		padding-right: 0px;
	}
	&& .paper-job_item-chip-box .MuiChip-root span{
		padding-left: 0px;
		padding-right: 0px;
	}
	&& .paper-job_item-chip-box .MuiChip-root,
	&& .paper-job_item-recomendation > div{
		border: 3px solid #A0A5EC;
		border-radius: 33.824px;
		padding: 8px;
		height: 42px;
		font-weight: 600;
		font-size: 15px;
		line-height: 26px;
	}
`;

export default Wrapper;