import styled from 'styled-components';

const Wrapper = styled.div`
    && .paper-acordion_btn-back{
        min-width: 145px;
    }
    && .paper-title_acordion-support p{
        margin-bottom: 0px!important;
    }
    && .paper-title_acordion-support{
        padding: 10px 10px!important;
        margin-bottom: 16px;
    }
    && .MuiPaper-root.MuiAccordion-root.Mui-expanded{
        margin: 0px 0;
    }
    && .MuiAccordionDetails-root{
        padding-left: 0px;
    }

    @media (max-width: 600px) {
        margin: 0px 36px;
        gap: 24px;
        display: flex;
        flex-direction: column;

        && .paper-title_acordion-support{
            margin-bottom: 0px;
        }
        && .paper-acordion_btn-back{
            min-width: 100%;
        }
        && .MuiAccordionSummary-root p{
            text-align: left;
            font-weight: 400!important;
            font-size: 16px!important;
            line-height: 23px!important;
        }
        && .MuiPaper-root{
            margin-bottom: 0px;
        }
    }
`;

export default Wrapper;