import styled from 'styled-components';
import Grid from '@mui/material/Grid';

const Wrapper = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 24px;

    && a::after,
    && button::after,
    && span::after{
        display: none!important;
    }

    && .MuiGrid-item > button,
    && .MuiGrid-item > a,
    && .MuiGrid-item > span > div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 48px;
        gap: 13px;
        font-weight: 500!important;
        font-size: 12px!important;
        line-height: 15px!important;
        color: #272830!important;
    }
    && .MuiGrid-item svg{
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08)!important;
        border-radius: 16px!important;
        padding: 12px;
    }
`;

export default Wrapper;