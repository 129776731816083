import axios from 'axios';
import {
	strPassword as utilsCheckStrPassword,
	strUserName as utilsCheckStrUserName,
	strUserLogin as utilsCheckStrUserLogin,
	strFilled as utilsCheckStrFilled,
	strEmail as utilsCheckStrEmail,
} from '@nest-datum-utils/check';
import { httpErrorMessage as utilsFormatHttpErrorMessage } from '@nest-datum-utils/format';
import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import { 
	actionApiFormClear,
	actionApiFormProp,
} from '@nest-datum-ui/Store';

export const fireRegister = (storeName, apiUrl) => async (callback = () => {}) => {
	try {
		actionApiFormProp(storeName, 'loader', true)();

		const validatedData = await utilsValidateStore(storeName, {
			login: {
				text: 'Login is not valid.',
				check: [ utilsCheckStrUserLogin ],
				isRequired: true,
			},
			email: {
				text: 'Email is not valid.',
				check: [ utilsCheckStrEmail ],
				isRequired: true,
			},
			firstname: {
				text: 'First name is not valid.',
				check: [ utilsCheckStrUserName ],
				isRequired: true,
			},
			lastname: {
				text: 'Last name is not valid.',
				check: [ utilsCheckStrUserName ],
				isRequired: true,
			},
			password: {
				text: 'You should enter at least 8 characters (max 25). You should enter both numbers and letters. Special characters are restricted.',
				check: [ utilsCheckStrPassword ],
				isRequired: true,
			},
			repeatedPassword: [{
				text: 'Password did not match',
				check: [
					utilsCheckStrPassword,
					(value, name, data) => {
						return value === data['password'];
					},
				],
			},{
				text: 'Required field. Fill in your password confirm',
				check: [ utilsCheckStrFilled ],
				isRequired: true
			}],
		});

		if(!validatedData['position']) {
			validatedData['position'] = localStorage.getItem('jobKind');
		}
		if(!validatedData['location']) {
			validatedData['location'] = localStorage.getItem('jobLocation');
		}

		if (validatedData) {
			await axios.post(apiUrl, validatedData);

			actionApiFormClear(storeName, { successfulRegistrationFlag: true })(callback);
		}
	}
	catch (err) {
		actionApiFormClear(storeName)();

		throw new Error(utilsFormatHttpErrorMessage(err, apiUrl));
	}
};
