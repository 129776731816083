import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let Password = ({ storeFormName, ...props }) => {
	return <StyledWrapper { ...props } error={props.error && <ErrorText title={props.error} />} />;
};

Password = React.memo(Password);
Password.defaultProps = {
};
Password.propTypes = {
};

export default Password;
