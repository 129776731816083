import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import Store, { 
	selectorMainExtract,
	actionApiListGet, 
	actionApiListPush,
	actionApiListProp,
	actionApiListSplice,
} from '@nest-datum-ui/Store';
import Grid from '@mui/material/Grid';
import TypographyBody from 'components/Typography/Body';
import Field from '@nest-datum-ui/Field';
import InputSkills from 'components/Input/Skills';
import ButtonPrimary from 'components/Button/Primary';
import ButtonSecondary from 'components/Button/Secondary';
import ListSkills from 'components/List/Skills';
import StyledWrapper from './Styled/Wrapper.jsx';
import handlerSubmit from './handler/submit.js';

/**
 * https://i.imgur.com/tiQLXbG.png
 */
let Skills = (props) => {
	let { pathname } = useLocation();
	const isUpdate = pathname.indexOf(`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}`) === 0;
	const userId = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'id' ]));
	const redirectUrl = `/${process.env.ROUTE_AUTHED}/${userId}/${process.env.ROUTE_EXPERIENCE}`;
	const routeName = React.useContext(ContextRoute);
	const { 
		[routeName]: { 
			form: { 
				storeName, 
				id, 
				apiUrl, 
				nextPageUrl, 
				prevPageUrl, 
			}, 
		}, 
	} = React.useContext(ContextProps);
	const suggestedSkillsStoreName = `${storeName}SuggestedSkills`;
	const [ viewAll, setViewAll ] = React.useState();
	const selectedSkillsData = useSelector(selectorMainExtract([ 'api', 'list', storeName, 'data' ]));
	const onSubmitWrapper = React.useCallback((e) => handlerSubmit(e, storeName, apiUrl, isUpdate?redirectUrl:nextPageUrl), [
		storeName,
		apiUrl,
		nextPageUrl,
		isUpdate,
		redirectUrl
	]);
	const onSelect = React.useCallback((e) => {
		if (e.target.nodeName === 'INPUT') {
			const value = e.target.value;
			const skillsData = ((Store().getState().api.list)['skills'] || {})['data'] || [];
			const skillsDataIndex = skillsData.findIndex((item) => item.value === value);

			if (skillsDataIndex > -1) {
				const skillItemObj = { ...skillsData[skillsDataIndex] };

				skillsData.splice(skillsDataIndex, 1);

				actionApiListProp('skills', 'data', [ ...skillsData ])(() => {
					actionApiListPush(storeName, skillItemObj)();
				});
			}
		};
	}, [
		storeName,
	]);
	const onDelete = React.useCallback((e, item, index) => actionApiListSplice(storeName, index, 1)(), [
		storeName,
	]);
	const onCollapse = React.useCallback((storeName) => (e, open) => setViewAll(open ? storeName : undefined), [
		setViewAll,
	]);

	React.useEffect(() => {
		if(!isUpdate) {
			window.history.pushState("","","")
		}
	});

	React.useEffect(() => {
		actionApiListGet(storeName, {
			apiUrl: `${process.env.URL_API_FORMS}/field/content`,
			page: 1,
			limit: 200,
			filter: {
				fieldId: 'happ-forms-field-skill-name',
				isUnique: true,
				userId,
			},
		})();
	}, [
		suggestedSkillsStoreName,
		storeName,
		userId,
	]);

	return <StyledWrapper { ...props }
		storeName={storeName} 
		id={id} 
		onSubmit={onSubmitWrapper}>
		{(!viewAll || viewAll !== suggestedSkillsStoreName)
			&& <TypographyBody>
				Add your core skills
			</TypographyBody>}
		{!viewAll
			&& <Field
				Component={InputSkills}
				form={id}
				onChange={onSelect}
				name="skills"
				placeholder="Skills*"
				className="inputSkills" />}
		{(!viewAll || viewAll !== suggestedSkillsStoreName)
			&& <ListSkills 
				manage 
				onDelete={onDelete} 
				onCollapse={onCollapse(storeName)}>
				{selectedSkillsData}
			</ListSkills>}
		{!viewAll
			&& <Grid 
				container 
				className='btns-form'>
				{isUpdate && <Grid
					item
					xs={false}>
					<ButtonSecondary to={isUpdate?redirectUrl:prevPageUrl}>
						Cancel
					</ButtonSecondary>
				</Grid>}
				<Grid
					item
					xs={false}>
					<ButtonPrimary form={id} type="submit">
						{isUpdate ? "Save" : "Save and Continue"}
					</ButtonPrimary>
				</Grid>
			</Grid>}
	</StyledWrapper>;
};

Skills = React.memo(Skills);
Skills.defaultProps = {
};
Skills.propTypes = {
};

export default Skills;
