
const config = {
	form: {
		storeName: 'cv-builder-upload-complete',
		id: 'cv-builder-upload-complete',
		nextPageUrl: `/${process.env.ROUTE_AUTHED}`,
	},
};

export default config;
