import styled from 'styled-components';
import Transparent from '../../Transparent';

const Wrapper = styled(Transparent)`
    box-shadow: none!important;

    && .listBoxScroll{
        display: flex;
        flex-direction: column;

        .listBox{
            display: flex;
            gap: 16px 28px;
            margin-bottom: 24px;
        }
        .MuiGrid-root > .MuiBox-root {
            flex-basis: calc(50% - 14px);
            min-width: auto;
            max-width: auto;
        }
        .MuiGrid-root > .MuiBox-root > span{   
            padding: 32px!important;
            box-sizing: border-box;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
            border-radius: 16px;
        }
    }

    @media (max-width: 600px) {
        && .listBoxScroll .MuiGrid-root > .MuiBox-root {
            flex-basis: 100%;
        }
    }
    
`;

export default Wrapper;