import axios from 'axios';
import Store, { 
	hookUrlNavigate,
	actionApiFormProp,
	actionApiFormPurge,
} from '@nest-datum-ui/Store';
import { strIdExists as utilsCheckStrIdExists } from '@nest-datum-utils/check';
import { 
	urlApiStr as  utilsFormatUrlApiStr, 
	httpErrorMessage as utilsFormatHttpErrorMessage, 
} from '@nest-datum-utils/format';
import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import { 
	strFilled as utilsCheckStrFilled,
} from '@nest-datum-utils/check';

const submit = async (e, storeName, apiUrl, nextPageUrl) => {
	const data = (Store()
		.getState()
		.api
		.list[storeName] || {})
		.data || [];

	const validatedData = await utilsValidateStore(storeName, {
		skills: {
			text: 'Required field. Fill in your skills',
			check: [ utilsCheckStrFilled ],
			isRequired: true,
		},
	});

	if (validatedData) {
		actionApiFormProp(storeName, 'loader', true)(async () => {
			try {
				const response = await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/content`), {
					formId: 'happ-forms-skills',
					contentStatusId: 'happ-forms-content-status-active', 
				});
				const id = ((response || {}).data || {}).id;

				if (!utilsCheckStrIdExists(id)) {
					throw new Error('Content ID is incorrect.');
				}
				setTimeout(async () => {
					let i = 0;

					while (i < data.length) {
						await axios.post(utilsFormatUrlApiStr(`${process.env.URL_API_FORMS}/field/content/${id}`), {
							fieldId: 'happ-forms-field-skill-name',
							value: data[i].value,
						});
						i++;
					}
					setTimeout(() => {
						actionApiFormPurge(storeName)();
					}, 0);
					hookUrlNavigate(nextPageUrl);
				}, 0);
			}
			catch (err) {
				actionApiFormProp(storeName, 'loader', false)();

				throw new Error(utilsFormatHttpErrorMessage(err, apiUrl));
			}
		});
	}
};

export default submit;
