import { storeDispatch } from '../../../Store.js';

export const fireFormPurge = (storeName) => async (callback = () => {}, prefix = 'api') => await storeDispatch(prefix, prefix +'.formPurge', { 
	payload: { 
		storeName,
		callback, 
	}, 
});

export const reducerFormPurge = (state, action) => {
	delete state.form[action.payload.storeName];

	setTimeout(() => action.payload.callback(state, action), 0);

	return {
		...state,
		form: {
			...state.form,
		},
	};
};
