import React from 'react';
import { useSelector } from 'react-redux';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import { 
	selectorMainExtract,
	actionApiListProp, 
} from '@nest-datum-ui/Store';
import Grid from '@mui/material/Grid';
import { ReactComponent as ViewListIconSmall } from 'static/svg/icons/viewList-small.svg';
import { ReactComponent as ViewCardsIconSmall } from 'static/svg/icons/viewCards-small.svg';

import IconButton from 'components/IconButton';
import SelectStatus from 'components/Select/Status';
import StyledWrapper from './Styled/Wrapper.jsx';

let Filter = (props) => {
	const routeName = React.useContext(ContextRoute);
	const { [routeName]: { form: { storeName } } } = React.useContext(ContextProps);
	let viewType = useSelector(selectorMainExtract([ 'api', 'list', storeName, 'viewType' ])) || 'table';
	const onList = React.useCallback(() => actionApiListProp(storeName, 'viewType', 'list')(), [
		storeName,
	]);
	const onTable = React.useCallback(() => actionApiListProp(storeName, 'viewType', 'table')(), [
		storeName,
	]);
	
	return <StyledWrapper { ...props }>
		<Grid container>
			<Grid item xs={false} className="paper-job_filter-title">
				View:
			</Grid>
			<Grid item xs={false} className="paper-job_filter-view-style">
				<IconButton { ...(viewType === 'table') ? { disabled: true } : { onClick: onTable } }>
					<ViewListIconSmall />
					<ViewListIconSmall />
					<ViewListIconSmall />
				</IconButton>
				<IconButton { ...(viewType === 'list') ? { disabled: true } : { onClick: onList } }>
					<ViewCardsIconSmall />
				</IconButton>
			</Grid>
			<Grid item xs={false} className="paper-job_select-grid">
				<SelectStatus 
					name="statusId"
					label="Status" />
			</Grid>
		</Grid>
	</StyledWrapper>;
};

Filter = React.memo(Filter);
Filter.defaultProps = {
};
Filter.propTypes = {
};

export default Filter;
