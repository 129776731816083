import styled from 'styled-components';
import Paper from '../../Paper.jsx';

const Wrapper = styled(Paper)`
    margin-bottom: 32px;
    padding: 32px 38px 32px 43px;
    border-radius: 16px!important;

    && .MuiGrid-container .MuiGrid-item{
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
    }
    && .MuiGrid-container .MuiGrid-item:first-child{
        justify-content: space-between;
        align-items: center;
        display: flex;
        margin-bottom: 16px;
    }

    && .MuiGrid-container .MuiGrid-item p:nth-child(1){
        font-weight: 600!important;
    }
    
    && .MuiGrid-container .MuiGrid-item:nth-child(2) p{
        color: #616161!important;
    }

    && .MuiGrid-container .MuiGrid-item.paper-education_view-all p{
        color: #0A0A0A!important;
        font-size: 12px!important;
        line-height: 17px!important;
        text-decoration-line: underline!important;
    }
    && .paper-education_view-all{
        cursor: pointer;
        padding-bottom: 0px!important;
    }

    && .MuiGrid-root a{
        padding: 0px!important;
    }

    &.preview-paper_data-added .MuiGrid-item .MuiGrid-container:last-child svg{
        width: 17px;
        height: 17px;
    }
    &.preview-paper_data-added{
        padding: 22px 0px 18px 0px;
    }

    &.preview-paper_data-added .MuiGrid-container:first-child .MuiGrid-item:first-child{
        padding: 0px 43px 20px 43px;
        margin-bottom: 0px;
    }
    &.preview-paper_data-null .paper-box-info_list-of-items .MuiGrid-item{
        margin-bottom: 0px!important;
    }
    &.preview-paper_data-added .paper-box-info_list-of-items{
        display: flex;
        padding: 12px 43px 0px 43px;
        border-top: 1px solid #F5F5F5;
    }
    &.preview-paper_data-added .paper-box-info_list-of-items .MuiGrid-item:first-child{
        gap: 8px;
        padding: 0px!important;
        justify-content: flex-start!important;
    }
    &.preview-paper_data-added .paper-box-info_list-of-items .MuiGrid-item:nth-child(2){
        justify-content: flex-end!important;
    }

    && .paper-box-info_list-of-items-line > div:last-child{
        border-bottom: 0px;
    }
    && .paper-box-info_list-of-items-line > div{
        border-bottom: 1px solid #F5F5F5;
        padding: 15px 43px 15px 43px;
        width: inherit;
    }
    && .paper-box-info_list-of-items-line{
        padding: 0px;
    }

    @media (max-width: 600px) {
        padding: 0px;
        box-shadow: none!important;

        && .preview-paper_experience-page-grid.preview-paper_experience-template .paper-box-info_list-of-items-line > div{
            padding: 20px 24px;
        }
        && .preview-paper_experience-page-grid .MuiGrid-container > .MuiGrid-item:nth-child(1) div{
            border: 0px;
            border-radius: 0px;
            padding 0px;
        }
        && .preview-paper_experience-page-grid .MuiGrid-container > .MuiGrid-item:nth-child(1) {
            flex-direction: column!important;
        }

        && .preview-paper_experience-page-grid .paper-box-info_box-of-items{
            padding: 24px;
        }
        && .MuiGrid-container .MuiGrid-item:first-child{
            padding: 0px 16px;
        }
        &.preview-paper_data-added{
            padding: 0px;
        }
        && .paper-box-info_box-of-items{
            padding: 24px 16px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
            border-radius: 16px;
        }
        &.preview-paper_data-added .paper-box-info_list-of-items .MuiGrid-item:nth-child(1) {
            display: flex;
            flex-direction: row;
            flex: 11;
            align-items: flex-start;
        }
        &.preview-paper_data-added .paper-box-info_list-of-items .MuiGrid-item:nth-child(2) {
            flex-direction: row;
            justify-content: flex-end!important;
            align-items: flex-end;
            flex: 1;
        }
        &.preview-paper_data-added .paper-box-info_list-of-items .MuiGrid-item{
            flex-wrap: wrap;
        }
        &.preview-paper_data-added .paper-box-info_list-of-items{
            border-top: 0px;
            padding: 0px!important;
            flex-wrap: wrap;
        }
        &.preview-paper_data-added .MuiGrid-item .MuiGrid-container:last-child svg{
            margin-bottom: 6px;
        }
        &.preview-paper_data-added > .MuiGrid-container:first-child > .MuiGrid-item:first-child{
            padding: 0px 16px!important;
            margin-bottom: 16px!important;
        }

        &.preview-paper_data-added > .MuiGrid-container.preview-paper_experience-page-grid > .MuiGrid-item:nth-child(1) {
            padding-right: 22px!important;
        }
        &.preview-paper_experience-box-false .preview-paper_experience-page-grid .paper-box-info_box-of-items {
            border: 1px solid #EDEDED;
        }
        &.preview-paper_experience-box-false .paper-box-info_list-of-items .MuiGrid-item:nth-child(2) {
            align-items: flex-start;
        }

        &.preview-paper_experience-box-true .paper-box-info_list-of-items {
            gap: 15px;
        }
        &.preview-paper_experience-box-true .paper-box-info_box-of-items {
            box-shadow: none!important;
            padding: 0px!important;
        }
        &.preview-paper_experience-box-true .paper-box-info_list-of-items .paper-education_view-all{
            border: 0px;
            box-shadow: none;
            padding-top: 9px;
        }
        &.preview-paper_experience-box-true .paper-box-info_list-of-items > div {
            border: 1px solid #EDEDED;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
            border-radius: 16px;
            padding: 24px;
        }
        &.preview-paper_experience-box-true .paper-box-info_list-of-items > div .MuiGrid-container .MuiGrid-item:last-child {
            flex: 3;
            flex-direction: row;
        }
    }
`;

export default Wrapper;