import React from 'react';
import PropTypes from 'prop-types';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let FirstName = (props) => {
	return <StyledWrapper { ...props } error={props.error && <ErrorText title={props.error} />} />;
};

FirstName = React.memo(FirstName);
FirstName.defaultProps = {
	label: 'First Name',
	name: 'lastname',
};
FirstName.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
};

export default FirstName;
