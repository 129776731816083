
const config = {
	form: {
		storeName: 'dashboard',
		id: 'dashboard',
		apiUrl: `${process.env.URL_API_JOBS}/post`,
	},
};

export default config;
