import React from 'react';
import { ContextRoute } from '@nest-datum-ui/Context';
import PrivacyPolisiesDoc from 'components/Paper/Docs/PrivacyPolisies';

let PrivacyPolisies = () => {
	return <ContextRoute.Provider>
		<PrivacyPolisiesDoc />
	</ContextRoute.Provider>;
};

PrivacyPolisies = React.memo(PrivacyPolisies);
PrivacyPolisies.defaultProps = {
};
PrivacyPolisies.propTypes = {
};

export default PrivacyPolisies;
