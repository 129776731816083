import styled from 'styled-components';
import Grid from '@mui/material/Grid';

const Wrapper = styled(Grid)`
    max-height: initial;
    overflow-y: scroll;
    width: 100%;
    

    && .MuiGrid-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
    }

    && a::after,
    && button::after,
    && span::after{
        display: none!important;
    }

    && .MuiGrid-item > button,
    && .MuiGrid-item > a,
    && .MuiGrid-item > span > div{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 26px;
        font-weight: 500!important;
        font-size: 16px!important;
        line-height: 23px!important;
        color: #272830!important;
    }
    && .MuiGrid-item svg{
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08)!important;
        border-radius: 16px!important;
        padding: 12px;
    }
    && .paper-mobile_close-menu-btn {
        margin-top: 48px;
    }
    && .paper-mobile_close-menu-btn svg{
        background: #2E3BE3;
    }
`;

export default Wrapper;