import React from 'react';
import MenuProfile from 'components/Menu/Profile';
import MenuButtonsProfile from 'components/Menu/Profile/Buttons';
import Paper from '../../Paper.jsx';
import Grid from '@mui/material/Grid';
import StyledWrapper from './Styled/Wrapper.jsx';

let Menu = ({ hideBtnsSection, ...props }) => {
	return <StyledWrapper { ...props }>
		<Paper>
			<MenuProfile />
		</Paper>
		<Grid className="paper-menu-profile_btns-list">
			{ hideBtnsSection === true && <MenuButtonsProfile />}
		</Grid>
	</StyledWrapper>;
};

Menu = React.memo(Menu);
Menu.defaultProps = {
	hideBtnsSection: false
};
Menu.propTypes = {
};

export default Menu;
