
const config = {
	form: {
		storeName: 'got-hired',
		id: 'got-hired',
		apiUrl: `${process.env.URL_API_SSO}/user`,
	},
};

export default config;
