
const accessStatus = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Statuses',

	pageUrl: 'accesses/statuses',
	pageTitle: 'Statuses',
};

export default accessStatus;
