import styled from 'styled-components';

const Wrapper = styled.div`
    border: 2px solid #8087E6;
    border-radius: 60px;
    padding 3px 10px;
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 20px!important;
    color: #0A0A0A!important;
`;

export default Wrapper;