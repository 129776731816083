import { 
	actionApiFormCreateFile,
	actionApiFormProp,
} from '@nest-datum-ui/Store';

const submit = (e, storeName, optionListStoreName, optionListApiUrl) => {
	const files = e.target.files;

	files['systemId'] = 'files-system-avatars';
	files['parentId'] = 'files-folder-avatar';

	actionApiFormProp(storeName, 'loader', true)(() => {
		actionApiFormCreateFile(process.env.URL_API_FILES_FILE, { files })(({ filesResponses }) => {
			if (filesResponses.length > 0 && ((filesResponses[0] || [])[0] || {})['path']) {
				actionApiFormProp('avatar', 'path', ((filesResponses[0] || [])[0] || {})['path'])();
			}
			actionApiFormProp(storeName, 'loader', false)();
		});
	});
};

export default submit;
