import axios from 'axios';
import {
	strPassword as utilsCheckStrPassword,
	strFilled as utilsCheckStrFilled,
} from '@nest-datum-utils/check';
import { httpErrorMessage as utilsFormatHttpErrorMessage } from '@nest-datum-utils/format';
import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import { 
	hookUrlProperty,
	hookSnackbar, 
	actionApiFormClear,
	actionApiFormProp,
} from '@nest-datum-ui/Store';

export const fireReset = async (storeName, apiUrl) => {
	try {
		actionApiFormProp(storeName, 'loader', true)();

		const verifyKey = hookUrlProperty('verifyKey');
		
		if (!utilsCheckStrFilled(verifyKey)) {
			hookSnackbar(`Verify key is empty.`);

			return actionApiFormProp(storeName, 'loader', false)();
		}
		const validatedData = await utilsValidateStore(storeName, {
			password: {
				text: 'You should enter at least 8 characters (max 25). You should enter both numbers and letters. Special characters are restricted.',
				check: [
					utilsCheckStrPassword,
				],
			},
			repeatedPassword: {
				text: 'Password did not match',
				check: [
					utilsCheckStrPassword,
					(repeatedPassword, all) => repeatedPassword === all['password'],
				],
			},
		});

		if (validatedData) {
			const password = validatedData['password'];
			const repeatedPassword = validatedData['repeatedPassword'];
			
			await axios.post(apiUrl, { password, repeatedPassword, verifyKey });

			actionApiFormClear(storeName, { successfulPasswordChangeFlag: true })();
		}
	}
	catch (err) {
		actionApiFormClear(storeName)();

		throw new Error(utilsFormatHttpErrorMessage(err, apiUrl));
	}
};
