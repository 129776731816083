
const config = {
	form: {
		storeName: 'auth-sign-up',
		id: 'auth-sign-up',
		apiUrl: `${process.env.URL_API_SSO}/user/register`,
		nextPageUrl: `/${process.env.ROUTE_VERIFY_MESSAGE}`,
	},
	lastStep: {
		nextPageUrl: `/${process.env.ROUTE_SiGN_UP}`,
	},
};

export default config;
