import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import { arrToObj as formatArrToObj } from '@nest-datum-utils/format';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputInt from '@nest-datum-ui/Input/Int';
import Select from 'components/Select';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let Phone = ({
	storeName,
	name,
	onCountry,
	onChange,
	onInput,
	countryProps,
	value,
	countryDefaultValue,
	error,
	...props
}) => {
	const [country, setCountry] = React.useState(() => countryDefaultValue);
	const data = useSelector(selectorMainExtract(['api', 'list', 'countries', 'data']));
	const dataMemo = React.useMemo(() => data ?? [], [data]);
	const usIndex = dataMemo.findIndex((item) => item.name === 'US');

	if (usIndex !== -1) {
		const usObject = dataMemo.splice(usIndex, 1)[0];
		dataMemo.unshift(usObject);
	}
	const options = React.useMemo(
		() =>
			formatArrToObj(
				(dataMemo.find((item) => item['id'] === country) || {})['regionContents'] || [],
				'typeOptionId',
			),
		[country, dataMemo],
	);

	const filterMemo = React.useMemo(
		() => ({
			typeId: 'countries_type_country',
			regionContents: {
				typeOptionId: [
					'$In',
					'happ-countries-type-option-code',
					'happ-countries-type-option-length',
					'happ-countries-type-option-iso1',
					'happ-countries-type-option-iso2',
				],
			},
		}),
		[],
	);
	const phoneLength = options['happ-countries-type-option-length']
		? Number(options['happ-countries-type-option-length']['value'])
		: 10;
	const onInputWrapper = React.useCallback(
		(e) => {
			e.target.value = e.target.value.slice(0, phoneLength);
			e.target['additionalValue'] = country;

			onInput(e);
			onChange(e);
		},
		[onInput, onChange, country, phoneLength],
	);
	const onCountryWrapper = React.useCallback(
		(e) => {
			e.target['additionalValue'] = e.target.value;

			onChange({
				target: {
					value: '',
				},
				currentTarget: {
					value: '',
				},
			});
			onCountry(e);
			setCountry(e.target.value);
		},
		[setCountry, onCountry, onChange],
	);

	return (
		<StyledWrapper>
			<Grid container spacing={1}>
				<Grid item xs={4}>
					<Select
						{...countryProps}
						storeName="countries"
						apiUrl={`${process.env.URL_API_COUNTRIES}/region`}
						filter={filterMemo}
						name={`input-phone__${name}`}
						label="Country"
						onChange={onCountryWrapper}
					>
						{dataMemo.map((item) => (
							<MenuItem key={item.id} value={item.id}>
								{(() => {
									const itemOptions = formatArrToObj(
										item['regionContents'],
										'typeOptionId',
									);
									if (
										itemOptions['happ-countries-type-option-iso1'] &&
										itemOptions['happ-countries-type-option-code']
									) {
										return `${itemOptions['happ-countries-type-option-iso1']['value']} +${itemOptions['happ-countries-type-option-code']['value']}`;
									}
								})()}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={true}>
					<InputInt
						{...props}
						value={!country ? '' : value}
						name={name}
						label=""
						onChange={onChange}
						onInput={onInputWrapper}
					/>
				</Grid>
			</Grid>
			{error && <ErrorText className="input-phone__error-wrapper" title={error} />}
		</StyledWrapper>
	);
};

Phone = React.memo(Phone);
Phone.defaultProps = {
	name: 'phone',
	countryProps: { label: 'UA +380' },
	label: 'Phone',
	placeholder: 'Phone number',
	countryDefaultValue: 'happ-countries-state-ak',
	onInput: () => {},
	onCountry: () => {},
	onChange: () => {},
};
Phone.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onInput: PropTypes.func,
	onCountry: PropTypes.func,
	countryDefaultValue: PropTypes.string,
};

export default Phone;
