import React from 'react';

let UploadCv = () => {
	return <React.Fragment>
		UploadCv
	</React.Fragment>;
};

UploadCv = React.memo(UploadCv);
UploadCv.defaultProps = {
};
UploadCv.propTypes = {
};

export default UploadCv;
