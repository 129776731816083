import styled from 'styled-components';
import Typography from '@mui/material/Typography';

const Wrapper = styled(Typography)`
    font-family: Jost!important;
    font-style: normal;
    font-weight: 400!important;
    font-size: 14px!important;
    line-height: 20px!important;
    color: #0A0A0A;
`;

export default Wrapper;