import React from 'react';
import { useSelector } from 'react-redux';
import { 
	selectorMainExtract,
	actionApiFormGet, 
} from '@nest-datum-ui/Store';
import { arrToObj as formatArrToObj } from '@nest-datum-utils/format';
import Grid from '@mui/material/Grid';
import Avatar from 'components/Avatar';
import TypographyStatus from 'components/Typography/Status';
import TypographyStatusPrimary from 'components/Typography/Status/Primary';
import StyledWrapper from './Styled/Wrapper.jsx';

let Min = (props) => {
	const userStatusId = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'userStatusId' ]));
	const userUserOptions = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'userUserOptions' ])) || [];
	const userUserOptionsObj = formatArrToObj(userUserOptions, 'userOptionId');
	const statusName = useSelector(selectorMainExtract([ 'api', 'form', 'status', 'name' ])) || '';

	React.useEffect(() => {
		if (userStatusId) {
			actionApiFormGet('status', {
				apiUrl: `${process.env.URL_API_SSO}/user-status`,
				entityId: userStatusId,
			})();
		}
	}, [
		userStatusId,
	]);

	return <StyledWrapper container justifyContent="space-between" { ...props }>
		<Grid item xs={false}>
			<Avatar withUpload>
				{String((userUserOptionsObj['happ-sso-user-option-firstname'] || {})['content'] || '')[0] || 'U'}{(String(userUserOptionsObj['happ-sso-user-option-lastname'] || {})['content'] || '')[0] || 'N'}
			</Avatar>
		</Grid>
		<Grid item xs={true}>
			<TypographyStatusPrimary>
				{String((userUserOptionsObj['happ-sso-user-option-firstname'] || {})['content'] || '')} {String((userUserOptionsObj['happ-sso-user-option-lastname'] || {})['content'] || '')}
			</TypographyStatusPrimary>
			<TypographyStatus>
				<span>{String(statusName)}</span>
				<span className='paper-profile-min_search-status active'></span>
			</TypographyStatus>
		</Grid>
	</StyledWrapper>;
};

Min = React.memo(Min);
Min.defaultProps = {
};
Min.propTypes = {
};

export default Min;
