import React from 'react';
import { useSelector } from 'react-redux';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import { arrToObj as formatArrToObj } from '@nest-datum-utils/format';
import { arrFilled as utilsCheckArrFilled } from '@nest-datum-utils/check';
import Typography from 'components/Typography';
import StyledWrapper from './Styled/Wrapper.jsx';
import PaperExperienceItem from 'components/Paper/Preview/ItemExperience/Item';
import PropTypes from 'prop-types';

let Experience = (props) => {
	const data = useSelector(selectorMainExtract([ 'api', 'list', 'userFormContent', 'data' ])) || [];
	const [ visible, setVisible ] = React.useState(() => false);
	const setDataVisible = React.useCallback((e) => setVisible((currentState) => !currentState), [
		setVisible,
	]);

	return <StyledWrapper { ...props } 
		title="Experience"
		to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}/${process.env.ROUTE_EXPERIENCE}`} 
		isDataAdded={data.length !== 0 
			? data.length
			: 0} 
		hideEdit={true}
		setDataVisible={setDataVisible}
		isVisible={visible}
		isExperienceBlock={true}>
		{!utilsCheckArrFilled(data)
			? <Typography className="message-title_box">
				Add information about your work experience
			</Typography>
			: data
				.filter((content) => (content.formId === 'happ-forms-experience'))
				.slice(0, visible ? 1000 : 3)
				.map((content) => {
					const dataItem = formatArrToObj(content['fieldContents'], 'fieldId');
					
					return <PaperExperienceItem 
						key={Math.random()} 
						title={dataItem['happ-forms-field-experience-title']?dataItem['happ-forms-field-experience-title']['value']:""} 
						subtitle={dataItem['happ-forms-field-experience-company']?dataItem['happ-forms-field-experience-company']['value']:""}
						description={dataItem['happ-forms-field-achievemets']?dataItem['happ-forms-field-achievemets']['value']:""}
						date={dataItem['happ-forms-field-experience-date']?dataItem['happ-forms-field-experience-date']['value']:""}
						createdAt={content['createdAt']} />
				})}
	</StyledWrapper>;
};

Experience = React.memo(Experience);
Experience.defaultProps = {
	isDataAdded: false,
	hideEdit: true,
	isVisible: false,
	isExperienceBlock: true,
	setDataVisible: () => {}
};
Experience.propTypes = {
	setDataVisible: PropTypes.func,
};

export default Experience;
