import React from 'react';
import Typography from 'components/Typography';
import StyledWrapper from './Styled/Wrapper.jsx';
import PaperPayItem from 'components/Paper/Preview/ItemJob/Item';

let data = [
	{ title: "Remote" },
	{ title: "Hybrid remote" },
	{ title: "Temporary Remote" }
]

let Remote = (props) => {
	return <StyledWrapper { ...props } title="Remote" to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}/${process.env.ROUTE_REMOTE}`} isDataAdded={data.length !== 0 ? 1: 0}>
		{ data.length === 0 ? <Typography className="message-title_box">
			Add your desirable workplace settings
		</Typography>: data.map((item) => { return <PaperPayItem key={Math.random()} title={item.title} /> })}
	</StyledWrapper>;
};

Remote = React.memo(Remote);
Remote.defaultProps = {
	isDataAdded: false
};
Remote.propTypes = {
};

export default Remote;
