
const verify = {
	pageTitle: 'Activation',
	pageUrl: 'verify',

	id: 'sso-verify',
	storeName: 'sso-verify',
	apiUrl: 'user/verify',
};

export default verify;
