import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { 
	selectorMainExtract, 
	actionApiFormMerge,
} from '@nest-datum-ui/Store';
import { 
	obj as utilsCheckObj,
	objFileList as utilsCheckObjFileList, 
} from '@nest-datum-utils/check';
import { 
	ContextRoute,
	ContextService,
	ContextProps,
} from '@nest-datum-ui/Context';

let FieldMemo = ({ 
	Component,
	name,
	onChange,
	type,
	form: formId,
	value: propValue,
	defaultValue: propDefaultValue,
	...props 
}) => {
	const serviceName = React.useContext(ContextService);
	const routeName = React.useContext(ContextRoute);
	const { 
		[serviceName]: { 
			[routeName]: { 
				storeName: contextStoreName, 
			} = {}, 
		} = {}, 
	} = React.useContext(ContextProps);
	const storeName = formId ?? contextStoreName;
	const [ valueMemo, setValueMemo ] = React.useState(() => propValue ?? propDefaultValue);
	const loader = useSelector(selectorMainExtract([ 'api', 'form', storeName, 'loader' ]));
	const error = useSelector(selectorMainExtract([ 'api', 'form', storeName, 'errors', name ]));
	const onChangeWrapper = React.useCallback((e, value) => {
		Component['nodeTarget'] = e.target;

		const nodeTarget = Component['nodeTarget'];
		const newValue = (utilsCheckObj(e)
			&& utilsCheckObj(e.target))
				? ((type === 'checkbox')
					? e.target.checked
					: (e.target.files || (value ?? e.target.value)))
				: e;

		if (nodeTarget) {
			setValueMemo(newValue);
			setTimeout(() => {
				onChange({ target: nodeTarget });
				actionApiFormMerge(storeName, {
					errors: {},
					[name]: newValue,
					...nodeTarget['additionalValue']
						? { [`${name}_additionalValue`]: nodeTarget['additionalValue'] }
						: {},
				})();
			}, 0);
		}
	}, [
		setValueMemo,
		Component,
		type,
		storeName,
		name,
		onChange,
	]);

	return <Component 
		{ ...props }
		{ ...formId
			? { form: formId }
			: {} }
		storeName={storeName}
		name={name}
		loader={Number(!!loader)} 
		error={error} 
		value={(type === 'date')
			? (valueMemo
				? new Date(valueMemo)
				: new Date())
			: (utilsCheckObjFileList(valueMemo)
				? valueMemo
				: ((type === 'object' || type === 'daterange')
					? Object(valueMemo)
					: ((type === 'checkbox')
						? Boolean(valueMemo)
						: String(valueMemo || ''))))} 
		onChange={onChangeWrapper} />;
};

FieldMemo = React.memo(FieldMemo);

let Field = ({ 
	Component,
	name,
	type: propType,
	form: formId,
	value: propValue,
	defaultValue: propDefaultValue,
	...props 
}) => {
	const serviceName = React.useContext(ContextService);
	const routeName = React.useContext(ContextRoute);
	const { 
		[serviceName]: { 
			[routeName]: { 
				form, 
				list, 
			} = {}, 
		} = {}, 
	} = React.useContext(ContextProps);
	const [ ComponentMemo ] = React.useState(() => Component);
	const storeName = formId ?? (form || {}).storeName ?? (list || {}).storeName;
	const value = useSelector(selectorMainExtract([ 'api', 'form', storeName, name ])) ?? propValue ?? propDefaultValue;
	const type = ((Component['defaultProps'] || {})['type'] ?? propType) ?? ((Component['type'] || {}).name || '').toLowerCase();
	const valueMemo = React.useMemo(() => value, [
		value,
	]);

	return <FieldMemo
		Component={ComponentMemo}
		type={type}
		name={name}
		form={formId}
		defaultValue={valueMemo}
		{ ...props } />;
};

Field = React.memo(Field);
Field.defaultProps = {
	onChange: () => {},
};
Field.propTypes = {
	Component: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.func,
	]).isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	storeName: PropTypes.string,
};

export default Field;
