import styled from 'styled-components';
import Paper from '../../../Paper.jsx';

const Wrapper = styled(Paper)`
    position: fixed;
    top: 0px;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.8)!important;
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    padding: 82px 0px 0px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    gap: 47px;

    &.paper-popap-mobile_hidden{
        transition: all 0.2s ease-out!important;
        left: -100%;
    }
    &.paper-popap-mobile_visible{
        transition: all 0.2s ease-out!important;
        left: 0px;
    }

    && .paper-logo_mobile-popap svg {
        width: 127px;
        height: 95px;
        margin-left: 56px;
    }

    && .MuiGrid-container{
        flex-direction: column;
        padding-top: 5px;
        padding-bottom: 15px;

        .MuiGrid-item{
            width: 100%;
            padding-left: 56px;
            padding-right: 56px;
        }
    }

    @media (min-width: 601px) {
        display: none;
    }
`;

export default Wrapper