import axios from 'axios';
import Store, { 
	hookUrlNavigate, 
	actionApiFormProp,
	actionApiFormPurge,
} from '@nest-datum-ui/Store';
import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import { 
	arrToObj as formatArrToObj,
	urlApiStr as  utilsFormatUrlApiStr, 
	httpErrorMessage as utilsFormatHttpErrorMessage, 
} from '@nest-datum-utils/format';
import { 
	strFilled as utilsCheckStrFilled,
	strEmail as utilsCheckStrEmail,
	strId as utilsCheckStrId,
	strUserFullName as utilsCheckStrUserFullName,
	exists as utilsCheckExists,
} from '@nest-datum-utils/check';

const submit = async (e, storeName, apiUrl, userId) => {
	const countriesList = (Store()
		.getState()
		.api
		.list['countries'] || {})
		.data || [];
	const userData = Store()
		.getState()
		.api
		.form['sso-sign-in'] || {};
	const {
		'happ-sso-user-option-phone_additionalValue': phoneId,
		'happ-sso-user-option-phone': phone,
	} = (Store()
		.getState()
		.api
		.form[storeName] || {});
	const options = formatArrToObj((countriesList.find((item) => item['id'] === phoneId) || {})['regionContents'] || [], 'typeOptionId');
	const phoneCode = '+'+ ((options || {})['happ-countries-type-option-code'] || {})['value'];
	const phoneLength = Number(((options || {})['happ-countries-type-option-length'] || {})['value']);
	const phoneProcessed = phoneCode + phone;
	const validatedData = await utilsValidateStore(storeName, {
		fullName: {
			text: 'Required field. Fill in your Full Name',
			check: [ utilsCheckStrUserFullName ],
			isRequired: true,
		},
		'happ-sso-user-option-position': {
			text: 'Required field. Fill in your Position',
			check: [ utilsCheckStrFilled ],
			isRequired: true,
		},
		'happ-sso-user-option-location': {
			text: 'Required field. Fill in your Location',
			check: [ utilsCheckStrFilled ],
			isRequired: true,
		},
		'happ-sso-user-option-phone': [{
			text: 'Wrong format of phone.',
			check: [
				(value, fieldName) => phoneProcessed.indexOf(phoneCode) === 0,
				(value, fieldName, a, b) => phoneProcessed.length === phoneLength + phoneCode.length,
			],
		},{
			text: 'Required field. Fill in your phone number',
			check: [ utilsCheckStrFilled ]
		}],
		email: {
			text: 'Required field. Fill in your Email',
			check: [ utilsCheckStrEmail ],
			isRequired: true,
		},
		'happ-sso-user-option-facebook': {
			text: 'Required field. Fill in your degree',
			check: [ utilsCheckStrFilled ],
		},
		'happ-sso-user-option-linkedin': {
			text: 'Wrong format',
			check: [ utilsCheckStrFilled ],
		},
		cvStatusId: {
			text: 'Wrong format',
			check: [ utilsCheckStrId ],
		},
	});

	if (validatedData) {
		actionApiFormProp(storeName, 'loader', true)(async () => {
			const fullNameSplit = validatedData['fullName'].split(' ');

			validatedData['happ-sso-user-option-firstname'] = fullNameSplit.shift();
			validatedData['happ-sso-user-option-lastname'] = fullNameSplit.join(' ');
			validatedData['happ-sso-user-option-phone'] = phoneProcessed;

			let i = 0,
				requestData = (userData['userUserOptions'] || [])
					.map((item) => ({
						...item,
						entityId: userData['id'],
						entityOptionId: item['userOptionId'],
						id: item['id'],
						content: validatedData[item['userOptionId']],
					}));

			try {
				while (i < requestData.length) {
					if (utilsCheckExists(requestData[i]['content'])) {
						await axios.patch(utilsFormatUrlApiStr(`${apiUrl}/${requestData[i]['id']}/option`), {
							content: requestData[i]['content'],
						});
					}
					i++;
				}
				actionApiFormProp('sso-sign-in', 'userUserOptions', [ ...requestData ])(() => {
					actionApiFormProp(storeName, 'loader', false)(() => {
						actionApiFormPurge(storeName)();
					});
					hookUrlNavigate(`/${process.env.ROUTE_AUTHED}/${userData['id']}`);
				});
			}
			catch (err) {
				actionApiFormProp(storeName, 'loader', false)();

				throw new Error(utilsFormatHttpErrorMessage(err, apiUrl));
			}
		});
	}
};

export default submit;
