import styled from 'styled-components';
import IconButton from '../../IconButton';

const Wrapper = styled(IconButton)`
	padding: 0px !important;
	margin: 0px !important;
	min-width: 0px !important;
	min-height: 0px !important;
	bottom: 32px !important;
	right: -16px !important;
`;

export default Wrapper;