import styled from 'styled-components';
import Box from '@mui/material/Box';

const Wrapper = styled(Box)`
    && .MuiPaper-root{
        border-radius: 16px!important;
        padding: 60px 16px;
        margin-bottom: 16px;
    }
    && .MuiPaper-root .MuiList-root{
        display: flex;
        flex-direction: column;
        gap: 22px;
    }
`;

export default Wrapper;