import React from 'react';
import PropTypes from 'prop-types';
import { ContextProps } from '@nest-datum-ui/Context';
import { actionMenuClose } from '@nest-datum-ui/Store';
import { actionSsoLogout } from '@nest-datum-ui-admin-lib/sso/src/components/Store';
import ButtonMenu from '@nest-datum-ui/Button/Menu';
import StyledWrapper from './Styled/Wrapper.jsx';

let User = ({
	id,
	onClose,
	...props
}) => {
	const { 
		sso: { 
			ssoSignIn: { 
				storeName, 
			}, 
		}, 
	} = React.useContext(ContextProps);
	const onCloseWrapper = React.useCallback((e) => {
		actionMenuClose()();
		onClose(e);
	}, [
		onClose,
	]);
	const onExit = React.useCallback((e) => {
		onCloseWrapper(e);
		actionSsoLogout(storeName);
	}, [
		storeName,
		onCloseWrapper,
	]);

	return <StyledWrapper id={id} { ...props }>
		<ButtonMenu onClick={onCloseWrapper}>
			Settings
		</ButtonMenu>
		<ButtonMenu to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_SUPPORT}`} onClick={onCloseWrapper}>
			Support
		</ButtonMenu>
		<ButtonMenu onClick={onExit} sx={{ color: '#D03375' }}>
			Sign Out
		</ButtonMenu>
	</StyledWrapper>;
};

User = React.memo(User);
User.defaultProps = {
	onClose: () => {},
};
User.propTypes = {
	id: PropTypes.string.isRequired,
	onClose: PropTypes.func,
};

export default User;
