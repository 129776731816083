import React from 'react';
import TypographyTitle from 'components/Typography/Title';
import AccordionFaq from 'components/Accordion/Faq';
import ButtonSecondary from 'components/Button/Secondary';
import PaperTitle from '../../Title';
import StyledWrapper from './Styled/Wrapper.jsx';
import StyledWrapperBox from './Styled/Box.jsx';

let Accordion = (props) => {
	return <StyledWrapperBox>
		<PaperTitle className="paper-title_acordion-support">
			<TypographyTitle>
				Title
			</TypographyTitle>
		</PaperTitle>
		<StyledWrapper { ...props }>
			<AccordionFaq />
		</StyledWrapper>
		<ButtonSecondary to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_SUPPORT}`} className="paper-acordion_btn-back">
			Back
		</ButtonSecondary>
	</StyledWrapperBox>;
};

Accordion = React.memo(Accordion);
Accordion.defaultProps = {
};
Accordion.propTypes = {
};

export default Accordion;
