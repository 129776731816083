import React from 'react';
import { ReactComponent as SvgIconsDeleteSmall } from 'static/svg/icons/trash-small-black.svg';
import StyledWrapper from './Styled/Wrapper.jsx';

/**
 * https://i.imgur.com/ro4Rezs.png
 */
let Drop = (props) => {
	return <StyledWrapper { ...props }>
		<SvgIconsDeleteSmall />
	</StyledWrapper>;
};

Drop = React.memo(Drop);
Drop.defaultProps = {
};
Drop.propTypes = {
};

export default Drop;
