import styled from 'styled-components';
import Paper from '../../../Paper.jsx';

const Wrapper = styled(Paper)`
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08)!important;
    border-radius: 16px!important;
    padding: 24px 30px;
    margin-bottom: 16px;

    && .dateNotify{
        color: #757575;
        font-weight: 500!important;
        font-size: 12px!important;
        line-height: 17px!important;
        text-align: right;
    }

    && .notifyTitle{
        margin-bottom: 12px;
        font-weight: 700!important;
        font-size: 16px!important;
        line-height: 23px!important;
    }

    && .notifySubTitle{
        margin-bottom: 20px;
        font-weight: 400!important;
        font-size: 14px!important;
        line-height: 20px!important;
    }

    && .boxNotifyTags .MuiGrid-item{
        font-weight: 500!important;
        font-size: 16px!important;
        line-height: 23px!important;
    }
    && .boxNotifyTags .MuiGrid-container{
        align-items: center;
    }
    && .boxNotifyTags .MuiGrid-item{
        display: flex;
    }

    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        padding: 16px 24px 24px 24px;
        gap: 12px;

        && .notifySubTitle{
            order: 3;
            margin-bottom: 0px;
        }

        && .MuiGrid-container:first-child{
            flex-direction: column-reverse;
            gap: 8px;
        }

        && .boxNotifyTags p .MuiGrid-container svg,
        && .boxNotifyTags p .MuiGrid-container span{
            display: block;
        }
        && .boxNotifyTags p .MuiGrid-container{
            display: flex;
            flex-direction: row;
        }
        && .boxNotifyTags{
            flex-direction: column;
        }
    }
`;

export default Wrapper;