import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import Grid from '@mui/material/Grid';
import Field from '@nest-datum-ui/Field';
import ButtonMenu from 'components/Button/Menu';
import ButtonSecondary from 'components/Button/Secondary';
import InputText from 'components/Input/Text';
import InputDateRange from 'components/Input/DateRange';
import InputEducationDegree from 'components/Input/Education/Degree';
import InputEducationStudyField from 'components/Input/Education/StudyField';
import Checkbox from 'components/Checkbox';
import ButtonPrimary from 'components/Button/Primary';
import StyledWrapper from './Styled/Wrapper.jsx';
import handlerSubmit from './handler/submit.js';

/**
 * https://i.imgur.com/wD8k3vE.png
 */
let Education = ({ onSubmit, ...props }) => {
	let { pathname } = useLocation();
	const isUpdate = pathname.indexOf(`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}`) === 0;
	const userId = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'id' ]));
	const redirectUrl = `/${process.env.ROUTE_AUTHED}/${userId}/${process.env.ROUTE_EXPERIENCE}`;
	const routeName = React.useContext(ContextRoute);
	const { 
		[routeName]: { 
			form: { 
				storeName, 
				id, 
				apiUrl, 
				nextPageUrl, 
			}, 
		}, 
	} = React.useContext(ContextProps);
	const inProgress = useSelector(selectorMainExtract([ 'api', 'form', storeName, 'happ-forms-field-in-progress' ]));
	const onSubmitWrapper = React.useCallback((e) => {
		let isMulti = false,
			eProcessed = e;

		if (e.currentTarget.id === 'multi') {
			eProcessed = document.getElementById(e.currentTarget.dataset['multi']);
			isMulti = true;
		}

		let newNextPageUrl = (isUpdate && !isMulti)?redirectUrl:nextPageUrl;
		handlerSubmit(eProcessed, storeName, apiUrl, newNextPageUrl, isMulti);
		onSubmit(e);
	}, [
		storeName,
		apiUrl,
		nextPageUrl,
		onSubmit,
		redirectUrl,
		isUpdate
	]);

	useEffect(() => {
		if(!isUpdate) {
			window.history.pushState("","","")
		}
	})

	return <StyledWrapper { ...props }
		storeName={storeName} 
		id={id} 
		onSubmit={onSubmitWrapper}>
		{ !isUpdate && <Field
			Component={Checkbox}
			form={id}
			name="happ-forms-field-dont-have-education"
			label="I don’t have any education"
			className="checkbox-form"
			type="checkbox" /> }
		<Field
			Component={(props) => <InputEducationDegree
				{ ...props }
				storeName="titleDegree" />}
			form={id}
			name="happ-forms-field-education-degree"
			placeholder="Degree*" />
		<Field
			Component={(props) => <InputEducationStudyField
				{ ...props }
				storeName="studyField" />}
			form={id}
			name="happ-forms-field-education-stady"
			placeholder="Field of Study" />
		<Field
			Component={InputText}
			form={id}
			name="happ-forms-field-education-school"
			placeholder="School*" />
		<Field
			Component={InputDateRange}
			form={id}
			name="happ-forms-field-education-date"
			className="dateRange-form"
			inProgress={inProgress} />
		<Field
			Component={Checkbox}
			form={id}
			name="happ-forms-field-in-progress"
			label="Currently in progress"
			className="checkbox-form"
			type="checkbox" />
		<Field
			Component={InputText}
			form={id}
			name="happ-forms-field-achievemets"
			placeholder="Achievemets"
			className="textarea-form"
			rows={3}
			multiline />
		{ isUpdate === true && <ButtonMenu
			data-multi={id}
			id="multi"
			onClick={onSubmitWrapper}>
			Save and add another school
		</ButtonMenu>}
		<Grid container className="btns-form">
			{ isUpdate === true && <Grid
				item
				xs={false}>
				<ButtonSecondary to={redirectUrl}>
					Cancel
				</ButtonSecondary>
			</Grid> }
			<Grid
				item
				xs={false}>
				<ButtonPrimary
					type="submit"
					form={id}>
					{ isUpdate ? "Save" : "Save and Continue" }
				</ButtonPrimary>
			</Grid>
		</Grid>
	</StyledWrapper>;
};

Education = React.memo(Education);
Education.defaultProps = {
	onSubmit: () => {},
};
Education.propTypes = {
	onSubmit: PropTypes.func,
};

export default Education;
