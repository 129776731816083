import styled from 'styled-components';
import Pagination from '../../Table.jsx';

const Wrapper = styled(Pagination)`
    && tr > th:first-child,
    && tr > td:first-child{
        padding-left: 33px;
    }    
    && tr > th:last-child,
    && tr > td:last-child{
        padding-right: 33px;
    } 
    
    && tr > td:first-child{
        border-radius: 8px 0px 0px 8px;
    }    
    && tr > td:last-child{
        border-radius: 0px 8px 8px 0px;
    } 
    && tbody tr:nth-child(2n+1) > td{
        background: #F9FAFF;
    }
    && thead tr th{
        border-bottom: 0px;
        padding-bottom: 14px!important;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
    }
    && tbody tr:nth-child(2n+1) {
        border: 1px solid #FFFFFF;
    } 
    && tr > td{
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 8px;
        border-bottom: 0px;
    }
    && tr p{
        text-align: left;
    }
`;

export default Wrapper;