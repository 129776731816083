import React from 'react';
import { 
	Outlet,
	useLocation, 
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { arrToObj as formatArrToObj } from '@nest-datum-utils/format';
import { strFilled as utilsCheckStrFilled } from '@nest-datum-utils/check';
import {
	hookUrlNavigate,
	selectorMainExtract,
	actionApiListGet,
	actionApiListPurge,
} from '@nest-datum-ui/Store';
import SsoLayoutAuthed from '@nest-datum-ui-admin-lib/sso/src/layouts/Authed';

let Authed = ({ children }) => {
	const { pathname } = useLocation();
	const [ check, setCheck ] = React.useState(() => false); 
	const experienceData = useSelector(selectorMainExtract([ 'api', 'list', 'experienceTitle', 'data' ]));
	const experienceTitle = ((experienceData || [])[0] || {})['value'];
	const experienceExists = !(experienceData === undefined);
	const userUserOptions = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'userUserOptions' ]));
	const userUserOptionsProcessedLoaded = !!userUserOptions;
	const userUserOptionsProcessed = userUserOptions || [];
	const userUserOptionsObj = formatArrToObj(userUserOptionsProcessed, 'userOptionId');
	const isReady = utilsCheckStrFilled((userUserOptionsObj['happ-sso-user-option-position'] || {})['content'] || experienceTitle)
		&& utilsCheckStrFilled((userUserOptionsObj['happ-sso-user-option-location'] || {})['content']);
	const getStartedReadyPosition = utilsCheckStrFilled((userUserOptionsObj['happ-sso-user-option-position'] || {})['content']);
	const getStartedReadyLocation =  utilsCheckStrFilled((userUserOptionsObj['happ-sso-user-option-location'] || {})['content']);

	React.useEffect(() => {
		if (!experienceExists
			&& userUserOptionsProcessedLoaded) {
			actionApiListGet(`experienceTitle`, {
				apiUrl: `${process.env.URL_API_FORMS}/field/content`,
				page: 1,
				limit: 1,
				filter: {
					fieldId: 'happ-forms-field-experience-title',
				},
			})(() => setCheck(true));
		}
	}, [
		setCheck,
		experienceExists,
		userUserOptionsProcessedLoaded,
	]);

	React.useEffect(() => {
		if (check
			&& !getStartedReadyPosition) {			
			hookUrlNavigate('/get-started');
		} else if (check
			&& !getStartedReadyLocation) {			
			hookUrlNavigate('/get-started/location');
		}
	}, [
		isReady,
		getStartedReadyLocation,
		getStartedReadyPosition,
		check,
	]);

	React.useEffect(() => {
		if (check
			&& !isReady 
			&& userUserOptionsProcessedLoaded
			&& window.location.pathname.indexOf('/app/cv-builder') === -1) {			
			hookUrlNavigate('/app/cv-builder');
		}
	}, [
		isReady,
		userUserOptionsProcessedLoaded,
		check,
	]);

	React.useEffect(() => () => {
		actionApiListPurge(`experienceTitle`)();
	}, [
	]);

	return (window.location.pathname.indexOf(`/${process.env.ROUTE_SiGN_IN}`) === 0
		|| window.location.pathname.indexOf(`/${process.env.ROUTE_SiGN_UP}`) === 0
		|| window.location.pathname.indexOf(`/${process.env.ROUTE_RECOVERY}`) === 0
		|| window.location.pathname.indexOf(`/${process.env.ROUTE_RESET}`) === 0
		|| window.location.pathname.indexOf(`/${process.env.ROUTE_VERIFY}`) === 0)
		? children
		: <SsoLayoutAuthed>
			{(!userUserOptionsProcessedLoaded 
				|| isReady
				|| pathname.indexOf('/app/cv-builder') === 0)
				? (children || <Outlet />)
				: <React.Fragment />}
		</SsoLayoutAuthed>;
};

Authed = React.memo(Authed);
Authed.defaultProps = {
};
Authed.propTypes = {
};

export default Authed;
