import React from 'react';
import { useSelector } from 'react-redux';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import { arrToObj as formatArrToObj } from '@nest-datum-utils/format';
import FormAvatar from 'components/Form/Avatar';
import Typography from 'components/Typography';
import TypographyBody from 'components/Typography/Body';
import TypographyInfoPrimary from 'components/Typography/Info/Primary';
import ButtonHeaderSecondary from 'components/Button/Header/Secondary';
import Grid from '@mui/material/Grid';
import StyledWrapper from './Styled/Wrapper.jsx';

let Aside = (props) => {
	const userUserOptions = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'userUserOptions' ])) || [];
	const experienceTitle = useSelector(selectorMainExtract([ 'api', 'list', 'experienceTitle', 'data', 0, 'value' ]));
	const userUserOptionsObj = formatArrToObj(userUserOptions, 'userOptionId');

	return <StyledWrapper { ...props }>
		<Grid container>
			<Grid item md={12}>
				<FormAvatar />
				<ButtonHeaderSecondary className="hiddenOnDesktop">
					Profile Status
				</ButtonHeaderSecondary>
			</Grid>
			<Grid item md={12}>
				<TypographyBody className="nameOfUserTitle">
					{String((userUserOptionsObj['happ-sso-user-option-firstname'] || {})['content'] || '')} {String((userUserOptionsObj['happ-sso-user-option-lastname'] || {})['content'] || '')}
				</TypographyBody>
				<ButtonHeaderSecondary className="hiddenOnMobile">
					Profile Status
				</ButtonHeaderSecondary>
				<Typography className="subTitle">
					Position
				</Typography>
				<TypographyInfoPrimary className="infoTitle">
					{String((userUserOptionsObj['happ-sso-user-option-position'] || {})['content'] || experienceTitle)}
				</TypographyInfoPrimary>
				{(userUserOptionsObj['happ-sso-user-option-location'] || {})['content']
					&& <React.Fragment>
						<Typography className="subTitle">
							Location
						</Typography>
						<TypographyInfoPrimary className="infoTitle">
							{String((userUserOptionsObj['happ-sso-user-option-location'] || {})['content'])}
						</TypographyInfoPrimary>
					</React.Fragment>}
				{(userUserOptionsObj['happ-sso-user-option-site'] || {})['content']
					&& <React.Fragment>
						<Typography className="subTitle hiddenOnMobile">
							Personal website
						</Typography>
						<TypographyInfoPrimary className="infoTitle hiddenOnMobile">
							{String((userUserOptionsObj['happ-sso-user-option-site'] || {})['content'])}
						</TypographyInfoPrimary>
					</React.Fragment>}
			</Grid>
		</Grid>
		
	</StyledWrapper>;
};

Aside = React.memo(Aside);
Aside.defaultProps = {
};
Aside.propTypes = {
};

export default Aside;
