import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';
import Typography from '@mui/material/Typography';
import { ReactComponent as ErrorIconSmall } from 'static/svg/icons/redWarning-small.svg';

let ErrorText = ({ title, ...props }) => {
	return <StyledWrapper { ...props }>
        <ErrorIconSmall />
        <Typography component="span" className="text-error-custom">{ title }</Typography>
    </StyledWrapper>;
};

ErrorText = React.memo(ErrorText);
ErrorText.defaultProps = {
};
ErrorText.propTypes = {
};

export default ErrorText;
