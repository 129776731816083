import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import { 
	selectorMainExtract,
	actionApiFormProp, 
	actionApiFormMerge,
	hookUrlNavigate,
} from '@nest-datum-ui/Store';
import { actionSsoRegister } from '@nest-datum-ui-admin-lib/sso';
import Grid from '@mui/material/Grid';
import Field from '@nest-datum-ui/Field';
// import TypographyTitle from 'components/Typography/Title';
import TypographySubtitle from 'components/Typography/Subtitle';
import TypographyInfo from 'components/Typography/Info';
import InputEmail from 'components/Input/Email';
import InputFirstName from 'components/Input/FirstName';
import InputLastName from 'components/Input/LastName';
import InputPassword from 'components/Input/Password';
// import ButtonGoogle from 'components/Button/Google';
// import ButtonFacebook from 'components/Button/Facebook';
import ButtonPrimary from 'components/Button/Primary';
import StyledWrapper from './Styled/Wrapper.jsx';
import handlerFirstStep from './handler/firstStep.js';
import ButtonInfo from 'components/Button/Info';
import Typography from 'components/Typography';

let SignUp = (props) => {
	const routeName = React.useContext(ContextRoute);
	const { 
		[routeName]: { 
			form: { 
				storeName, 
				id, 
				apiUrl, 
				nextPageUrl, 
			}, 
		}, 
	} = React.useContext(ContextProps);

	const [ step, setStep ] = React.useState(() => 0);
	const email = useSelector(selectorMainExtract([ 'api', 'form', storeName, 'email' ]));
	const onFirstStep = React.useCallback((e) => {
		handlerFirstStep(e, storeName, apiUrl, setStep);
	}, [
		storeName,
		apiUrl,
		setStep,
	]);
	const onSecondStep = React.useCallback((e) => {
		actionApiFormMerge(storeName, {
			login: email,
			position: localStorage.getItem(`jobKind`) || '',
			location: localStorage.getItem(`jobLocation`) || '',
		})(async () => {
			(await actionSsoRegister(storeName, apiUrl))(() => {
				hookUrlNavigate(nextPageUrl);
				actionApiFormProp(storeName, 'loader', false)();
			});
		});
	}, [
		storeName,
		apiUrl,
		email,
		nextPageUrl,
	]);
	useEffect(() => {
		step === 1 ? window.history.pushState(null, null, '#next') : window.history.pushState(null, null, null);

		window.addEventListener('hashchange', () => {
			if(!window.location.hash) setStep(0);
		});
	}, [ setStep, step ]);

	const steps = [ onFirstStep, onSecondStep ];

	return <StyledWrapper { ...props }
		storeName={storeName} 
		id={id} 
		onSubmit={steps[step]}>
		{(step > 0 && email)
			? <React.Fragment>
				<TypographySubtitle className="emailSubtitle">
					{email}
				</TypographySubtitle>
				<Grid className="inputsBox">
					<Field
						Component={InputPassword}
						form={id}
						label="Enter Password*"
						visibility={true}
						name="password" />
					<Field
						Component={InputPassword}
						form={id}
						label="Confirm Password*"
						visibility={true}
						name="repeatedPassword" />
				</Grid>
				<TypographyInfo className="infoText">
					By creating an account you understand<br />
					and agree to <a href="/doc-terms">HAPP’s Terms</a>. You also acknowledge<br />
					our <a href="/doc-cookie">Cookie</a> and <a href="/doc-privacy-policies">Privacy policies</a>.
				</TypographyInfo>
				<ButtonPrimary form={id} type="submit">
					Sign Up
				</ButtonPrimary>
			</React.Fragment>
			: <React.Fragment>
				{/* <Grid container className="googleFbBtnsBox">
					<Grid
						item
						xs={false}>
						<ButtonGoogle />
					</Grid>
					<Grid
						item
						xs={false}>
						<ButtonFacebook />
					</Grid>
				</Grid>
				<TypographyTitle className="titleOr">
					or
				</TypographyTitle> */}
				<Grid className="inputsBox">
					<Field 
						Component={InputEmail}
						form={id}
						label="Email*"
						name="email" />
					<Field 
						Component={InputFirstName}
						form={id}
						label="First Name*"
						name="firstname" />
					<Field 
						Component={InputLastName}
						form={id}
						label="Last Name* "
						name="lastname" />
				</Grid>
				<ButtonPrimary form={id} type="submit">
					Next Step
				</ButtonPrimary>
				<Typography component="div" className="infoTextDiv">
					<TypographyInfo component="span" className="infoText nonMargin">
						Already have an account?
					</TypographyInfo>
					<ButtonInfo component="span" to={`/${process.env.ROUTE_SiGN_IN}`}>
						Sign In
					</ButtonInfo>
				</Typography>
			</React.Fragment>}
	</StyledWrapper>;
};

SignUp = React.memo(SignUp);
SignUp.defaultProps = {
};
SignUp.propTypes = {
};

export default SignUp;
