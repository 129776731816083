import React from 'react';
import { ContextRoute } from '@nest-datum-ui/Context';
import DashboardProfile from 'components/Paper/DashboardProfile';

let Dashboard = () => {
	return <ContextRoute.Provider value="authedDashboard">
		<DashboardProfile />
	</ContextRoute.Provider>;
};

Dashboard = React.memo(Dashboard);
Dashboard.defaultProps = {
};
Dashboard.propTypes = {
};

export default Dashboard;
