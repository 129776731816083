import styled from 'styled-components';
import Title from '../../Title';

const Wrapper = styled(Title)`
    padding: 18px 34px;
    
    && .MuiGrid-container {
        align-items: center;
        gap: 20px;
    }
    && .paper-job_filter-title{
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #0A0A0A;
    }
    && .paper-job_filter-view-style{
        gap: 12px;
        display: flex;
        margin-right: 12px;

        button:first-child{
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
        button {
            padding: 0px;
        }
        .MuiButtonBase-root svg *{
            fill: #8087E6;
        }
        .Mui-disabled svg *{
            fill: #2E3BE3;
        }
    }
    && .paper-job_select-grid {
        height: 26px;

        .MuiSelect-select{
            width: 62px;
            color: #0A0A0A!important;
            padding-left: 0px!important;
            padding-right: 0px!important;
        }
        .MuiSelect-select,
        .MuiOutlinedInput-root{
            height: 26px;
        }
        fieldset {
            background: none!important;
            border-radius: 0px;
            box-shadow: none!important;
            height: 26px;
        }
        path{
            fill: #0A0A0A!important;
        }
        label {
            color: #0A0A0A!important;
            top: -9px;
            left: -13px;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
        }
        svg {
            width: 11px;
            height: 11px;
            margin-right: 0px;
        }
    }

    @media (max-width: 600px) {
        display: none;
    }
`;

export default Wrapper;