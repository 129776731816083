import React from 'react';
import { Outlet } from 'react-router-dom';
import StyledWrapper from './Styled/Wrapper.jsx';
import SsoLayoutAuthed from '@nest-datum-ui-admin-lib/sso/src/layouts/Authed';

let Authed = () => {
	return <StyledWrapper>
		<SsoLayoutAuthed>
			<Outlet />
		</SsoLayoutAuthed>
	</StyledWrapper>;
};

Authed = React.memo(Authed);
Authed.defaultProps = {
};
Authed.propTypes = {
};

export default Authed;
