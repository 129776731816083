import React from 'react';
import FormAvatar from 'components/Form/Avatar';
import { ReactComponent as SvgIconsUploadSmall } from 'static/svg/icons/upload-small.svg';
import StyledWrapper from './Styled/Wrapper.jsx';

let Upload = (props) => {
	return <StyledWrapper { ...props }>
		<FormAvatar disableStyles>
			<SvgIconsUploadSmall />
		</FormAvatar>
	</StyledWrapper>;
};

Upload = React.memo(Upload);
Upload.defaultProps = {
};
Upload.propTypes = {
};

export default Upload;
