import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';
import Grid from '@mui/material/Grid';
import PaperGotHiredMin from 'components/Paper/GotHired/Min';
import PaperProfileAside from 'components/Paper/Profile/Aside';
import PaperTitle from 'components/Paper/Title';
import PaperNotification from 'components/Paper/Notification';

let DashboardProfile = (props) => {
	return <StyledWrapper { ...props } sx={{ width: '100%' }}>
			<PaperGotHiredMin className="hiddenOnMobile" />
			<Grid container spacing={3} className="paper-dashboard_container">
				<Grid item md={3} className="paper-dashboard_item-aside">
					<PaperProfileAside />
				</Grid>
				<Grid item md={9}>
					<PaperTitle className="hiddenOnMobile">
						Notifications
					</PaperTitle>
					<PaperNotification />
				</Grid>
			</Grid>
		</StyledWrapper>;
};

DashboardProfile = React.memo(DashboardProfile);
DashboardProfile.defaultProps = {
};
DashboardProfile.propTypes = {
};

export default DashboardProfile;
