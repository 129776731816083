import styled from 'styled-components';
import PaperPrimary from '../../../Primary';

const Wrapper = styled(PaperPrimary)`
	border: none !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 26px;
	margin-bottom: 56px;
	margin-top: 56px;

	&& .paper-last-step_complete-title{
		margin-bottom: 0px!important;
	}
	&& .paper-last-step_complete-subtitle{
		margin-bottom: 26px;
		margin-top: 24px;
	}
	&& .paper-last-step_complete-btn{
		width: 100%;
	}

	&& .paper-last-step_send-again-btn{
		text-decoration: underline;
	}

	@media (max-width: 600px) {
		gap: 30px;
		min-height: calc(100vh - 140px);
    	justify-content: center;
		margin-bottom: 0px;
		margin-top: 100px;
		padding: 40px;

		&& .paper-last-step_send-again-btn{
			order: 5;
		}
		&& .paper-last-step_complete-title{
			order: 1;
			margin-bottom: 14px!important;
		}
		&& .paper-last-step_complete-subtitle{
			order: 3;
			margin-bottom: 0px!important;
		}
		&& svg {
			order: 2;
			width: 100%;
		}
		&& .paper-last-step_complete-btn{
			order: 4;
		}
	}
`;

export default Wrapper;