import styled from 'styled-components';
import Form from '../../Form.jsx';

const DesirableWorkplace = styled(Form)`
    && .titleSecondWorkplace{
        margin-top: 24px;
    }

    && .form-children__wrapper button{
        margin-top: 48px;
        width: 100%;
    }
`;

export default DesirableWorkplace;