import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import {
	selectorMainExtract, 
	actionMenuOpen, 
	actionApiFormGet,
} from '@nest-datum-ui/Store';
import { strIdExists as utilsCheckStrIdExists } from '@nest-datum-utils/check';
import { urlApiStr as utilsFormatUrlApiStr } from '@nest-datum-utils/format';
import IconButton from '@mui/material/IconButton';
import MenuHeaderUser from 'components/Menu/Header/User';
import StyledWrapper from './Styled/Wrapper.jsx';

let Header = ({ id, ...props }) => {
	const onMenu = React.useCallback((e) => actionMenuOpen(id, e.target)(), [
		id,
	]);
	const userUserOptions = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'userUserOptions' ])) || [];
	const avatarId = ((userUserOptions.filter((item) => item['userOptionId'] === 'happ-sso-user-option-avatar'))[0] || {})['content'];
	const avatar = useSelector(selectorMainExtract([ 'api', 'form', 'avatar' ]));
	const avatarPath = (avatar || {})['path'];
	const src = avatarPath
		? `${utilsFormatUrlApiStr(process.env.URL_API_FILES + avatarPath)}&rand=${uuidv4()}`
		: '';

	React.useEffect(() => {
		if (utilsCheckStrIdExists(avatarId)) {
			actionApiFormGet('avatar', { 
				apiUrl: process.env.URL_API_FILES_FILE, 
				entityId: avatarId, 
			})();
		}
	}, [
		avatarId,
	]);

	return <React.Fragment>
		<StyledWrapper 
			component={IconButton} 
			onClick={onMenu} 
			{ ...src
				? {
					src,
					alt: '',
				}
				: {} }
			{ ...props } />
		<MenuHeaderUser id={id} />
	</React.Fragment>;
};

Header = React.memo(Header);
Header.defaultProps = {
	id: 'menu-header-user'
};
Header.propTypes = {
};

export default Header;
