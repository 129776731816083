import React from 'react';

let ReviewCv = () => {
	return <React.Fragment>
		ReviewCv
	</React.Fragment>;
};

ReviewCv = React.memo(ReviewCv);
ReviewCv.defaultProps = {
};
ReviewCv.propTypes = {
};

export default ReviewCv;
