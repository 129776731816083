import React from 'react';
import Typography from 'components/Typography';
import StyledWrapper from './Styled/Wrapper.jsx';
import PaperPayItem from 'components/Paper/Preview/ItemJob/Item';

let data = [
	{ title: "English - fluent" },
	{ title: "German - fluent" },
];

let Languages = (props) => {
	return <StyledWrapper { ...props } title="Languages" to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}/${process.env.ROUTE_LANGUAGE}`} isDataAdded={data.length !== 0 ? 1: 0}>
		{ data.length === 0 ? <Typography className="message-title_box">
			Add languages you can speak
		</Typography>: data.map((item) => { return <PaperPayItem key={Math.random()} title={item.title} /> })}
	</StyledWrapper>;
};

Languages = React.memo(Languages);
Languages.defaultProps = {
	isDataAdded: false
};
Languages.propTypes = {
};

export default Languages;
