import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let InputDate = (props) => {
	return <StyledWrapper { ...props } error={props.error && <ErrorText title={props.error} />} />;
};

InputDate = React.memo(InputDate);
InputDate.defaultProps = {
};
InputDate.propTypes = {
};

export default InputDate;
