import React from 'react';
import PropTypes from 'prop-types';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let FullName = (props) => {
	return <StyledWrapper { ...props } error={props.error && <ErrorText title={props.error} />} />;
};

FullName = React.memo(FullName);
FullName.defaultProps = {
	placeholder: 'Full Name',
	name: 'fullName',
};
FullName.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
};

export default FullName;
