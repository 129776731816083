import styled from 'styled-components';

const Wrapper = styled.span`
    color: #D03375;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 8px;
    margin-top: 8px;

    && .text-error-custom {
        margin-bottom: 2px!important;
    }
    && svg{
        flex: none;
        margin-top: 5px!important;
        margin-right: 0px!important;
        width: 16px!important;
        height: 16px!important;
    }
`;

export default Wrapper;