import styled from 'styled-components';
import PaperPrimary from '../../../Primary';

const Wrapper = styled(PaperPrimary)`
	border: none !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 16px;

	&& .paper-uploadcv_complete-title{
		margin-bottom: 0px!important;
	}
	&& .paper-uploadcv_complete-subtitle{
		margin-bottom: 24px;
	}
	&& .paper-uploadcv_complete-btn{
		width: 100%;
	}

	@media (max-width: 600px) {
		gap: 30px;
		min-height: calc(100vh - 140px);
    	justify-content: center;

		&& .paper-uploadcv_complete-title{
			order: 1;
			margin-bottom: 14px!important;
		}
		&& .paper-uploadcv_complete-subtitle{
			order: 3;
			margin-bottom: 0px!important;
		}
		&& svg {
			order: 2;
		}
		&& .paper-uploadcv_complete-btn{
			order: 4;
		}
	}
`;

export default Wrapper;