import { createAppContext } from '@nest-datum-ui/Context';
import sso from '@nest-datum-ui-admin-lib/sso/src/config';
import files from '@nest-datum-ui-admin-lib/files/src/config';
import getStarted from 'routes/GetStarted/config.js';
import getStartedLocation from 'routes/GetStarted/Location/config.js';
import getStartedQuickOffers from 'routes/GetStarted/QuickOffers/config.js';
import authSignIn from 'routes/Auth/SignIn/config.js';
import authSignUp from 'routes/Auth/SignUp/config.js';
import authRecovery from 'routes/Auth/Recovery/config.js';
import authReset from 'routes/Auth/Reset/config.js';
import authVerify from 'routes/Auth/Verify/config.js';
import authedDashboard from 'routes/Authed/Dashboard/config.js';
import authedProfile from 'routes/Authed/Profile/config.js';
import authedProfileEdit from 'routes/Authed/Profile/Edit/config.js';
import authedProfileGotHired from 'routes/Authed/Profile/GotHired/config.js';
import authedCvBuilder from 'routes/Authed/CvBuilder/config.js';
import authedCvBuilderPhone from 'routes/Authed/CvBuilder/Phone/config.js';
import authedCvBuilderExperience from 'routes/Authed/CvBuilder/Experience/config.js';
import authedCvBuilderEducation from 'routes/Authed/CvBuilder/Education/config.js';
import authedCvBuilderSkills from 'routes/Authed/CvBuilder/Skills/config.js';
import authedCvBuilderDesirableWorkplace from 'routes/Authed/CvBuilder/DesirableWorkplace/config.js';
import authedCvBuilderLicences from 'routes/Authed/CvBuilder/Licences/config.js';
import authedCvBuilderCertificates from 'routes/Authed/CvBuilder/Certificates/config.js';
import authedCvBuilderLanguage from 'routes/Authed/CvBuilder/Language/config.js';
import authedCvBuilderJobType from 'routes/Authed/CvBuilder/JobType/config.js';
import authedCvBuilderPay from 'routes/Authed/CvBuilder/Pay/config.js';
import authedCvBuilderRelocate from 'routes/Authed/CvBuilder/Relocate/config.js';
import authedCvBuilderRemote from 'routes/Authed/CvBuilder/Remote/config.js';
import authedCvBuilderSchedule from 'routes/Authed/CvBuilder/Schedule/config.js';
import authedCvBuilderСomplete from 'routes/Authed/CvBuilder/Сomplete/config.js';
import authedJob from 'routes/Authed/Job/config.js';
import authedSupport from 'routes/Authed/Support/config.js';
import authedSupportLogin from 'routes/Authed/Support/Login/config.js';
import authedSupportProfile from 'routes/Authed/Support/Profile/config.js';
import authedSupportPrivacy from 'routes/Authed/Support/Privacy/config.js';
import authedSupportJobSearch from 'routes/Authed/Support/JobSearch/config.js';
import authedSupportJobApply from 'routes/Authed/Support/JobApply/config.js';
import authedSupportJobAlert from 'routes/Authed/Support/JobAlert/config.js';

const importSchema = {
	sso: createAppContext(sso),
	files: createAppContext(files),
	getStarted,
	getStartedLocation,
	getStartedQuickOffers,
	authSignIn,
	authSignUp,
	authRecovery,
	authReset,
	authVerify,
	authedDashboard,
	authedProfile,
	authedProfileEdit,
	authedProfileGotHired,
	authedCvBuilder,
	authedCvBuilderPhone,
	authedCvBuilderExperience,
	authedCvBuilderEducation,
	authedCvBuilderSkills,
	authedCvBuilderDesirableWorkplace,
	authedCvBuilderLicences,
	authedCvBuilderCertificates,
	authedCvBuilderLanguage,
	authedCvBuilderJobType,
	authedCvBuilderPay,
	authedCvBuilderRelocate,
	authedCvBuilderRemote,
	authedCvBuilderSchedule,
	authedCvBuilderСomplete,
	authedJob,
	authedSupport,
	authedSupportLogin,
	authedSupportProfile,
	authedSupportPrivacy,
	authedSupportJobSearch,
	authedSupportJobApply,
	authedSupportJobAlert,
};

export default importSchema;
