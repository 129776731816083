import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let Float = (props) => {
	return <StyledWrapper { ...props } error={props.error && <ErrorText title={props.error} />} />;
};

Float = React.memo(Float);
Float.defaultProps = {
};
Float.propTypes = {
};

export default Float;
