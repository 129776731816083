import React from 'react';
import PropTypes from 'prop-types';
import { ContextProps } from '@nest-datum-ui/Context';
import Field from '@nest-datum-ui/Field';
import InputImage from 'components/Input/Image';
import StyledWrapper from './Styled/Wrapper.jsx';
import handlerSubmit from './handler/submit.js';

let Avatar = ({ onSubmit, disableStyles, ...props }) => {
	const { 
		sso: { 
			ssoUserForm: { 
				storeName, 
				id, 
			},
			ssoUserOptionList: {
				storeName: optionListStoreName,
				apiFullUrl: optionListApiUrl,
			},
		} 
	} = React.useContext(ContextProps);
	const onSubmitWrapper = React.useCallback((e) => {
		handlerSubmit(e, storeName, optionListStoreName, optionListApiUrl);
		onSubmit(e);
	}, [
		storeName,
		optionListStoreName,
		optionListApiUrl,
		onSubmit,
	]);

	return <StyledWrapper { ...props }
		storeName={storeName} 
		id={id}
		className={disableStyles
			? 'disable-styles'
			: 'with-styles'}>
		<Field
			Component={InputImage}
			form={id}
			name="avatar"
			onChange={onSubmitWrapper} />
	</StyledWrapper>;
};

Avatar = React.memo(Avatar);
Avatar.defaultProps = {
	onSubmit: () => {},
	disableStyles: false,
};
Avatar.propTypes = {
	onSubmit: PropTypes.func,
};

export default Avatar;
