import styled from 'styled-components';
import IconButton from '../../../IconButton';

const Wrapper = styled(IconButton)`
    padding: 0px!important;
    margin: 0px;

    &:hover{
        background-color: transparent!important;
    }
`;

export default Wrapper;