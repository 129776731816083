import React from 'react';
import { useLocation } from 'react-router-dom'
import TypographyTitle from 'components/Typography/Title';
import FormSignUp from 'components/Form/SignUp';
import StyledWrapper from './Styled/Wrapper.jsx';
import LastStep from './LastStep';

let SignUp = (props) => {
	const location = useLocation();

	return location.pathname === "/verify-message" 
		? <LastStep />
		: <StyledWrapper { ...props }>
			<TypographyTitle>
				Create an Account
			</TypographyTitle>
			<FormSignUp />
		</StyledWrapper>
};

SignUp = React.memo(SignUp);
SignUp.defaultProps = {
};
SignUp.propTypes = {
};

export default SignUp;
