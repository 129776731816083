import styled from 'styled-components';
import Box from '@mui/material/Box';

const Wrapper = styled(Box)`
    && .MuiListItemIcon-root {
        min-width: 40px;
    }
    && .MuiList-root .MuiListItemText-root{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    && .MuiList-root li .MuiButtonBase-root{
        height: 43px;
        position: relative;
    }
    && .MuiList-root li span{
        font-weight: 600!important;
        font-size: 18px!important;
        line-height: 26px!important;
    }

    && .MuiList-root li a.MuiButtonBase-root svg > *,
    && .MuiList-root li a.MuiButtonBase-root{
        background-color: transparent!important;
        color: #424242!important;
    }

    && .MuiList-root li span.MuiButtonBase-root:hover svg > *,
    && .MuiList-root li span.MuiButtonBase-root:hover,
    && .MuiList-root li span.MuiButtonBase-root svg > *,
    && .MuiList-root li a.MuiButtonBase-root:hover svg > *,
    && .MuiList-root li a.MuiButtonBase-root:hover{
        background-color: #2E3BE3!important;
        color: #fff!important;
        fill: #fff!important;
    }

    && .MuiList-root li span.MuiButtonBase-root::after{
        content: "";
        width: 5px;
        height: 43px;
        background: #2E3BE3;
        border-radius: 0px 2px 2px 0px;
        position: absolute;
        right: -21px;
    }
    && .MuiList-root li span.MuiButtonBase-root div{
        display: flex;
        align-items: center;
    }

    @media (max-width: 600px) {
        display: flex;
        justify-content: center;
		ul {
            display: flex;
            gap: 32px;
            margin: 0px 0px 24px 0px;
        }
        && .MuiList-root li a,
        && .MuiList-root li span{
            font-size: 12px!important;
            line-height: 17px!important;
            padding: 0px 0px 8px 0px!important;
            height: auto!important;
            margin-right: 0px!important;
        }
        && .MuiList-root li span .MuiTypography-root{
            font-weight: 700!important;
        }
        && .MuiList-root li span a{
            font-weight: 400!important;
        }
        && .MuiList-root li{
            width: auto;
            text-align: center;
        }
        && .MuiListItemIcon-root{
            display: none!important;
        }
        && .MuiList-root li span,
        && .MuiList-root li span.MuiButtonBase-root:hover svg > *,
        && .MuiList-root li span.MuiButtonBase-root:hover,
        && .MuiList-root li span.MuiButtonBase-root svg > *,
        && .MuiList-root li a.MuiButtonBase-root:hover svg > *,
        && .MuiList-root li a.MuiButtonBase-root:hover{
            background: none!important;
            color: #0A0A0A!important;
        }
        && .MuiList-root li span.MuiButtonBase-root::after{
            content: "";
            width: 100%;
            height: 6px;
            background: #2E3BE3;
            border-radius: 2px;
            position: absolute;
            right: 0px;
            bottom: 0px;
        }
	}
`;

export default Wrapper;