import React from 'react';
import { func as utilsCheckFunc } from '@nest-datum-utils/check';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let DateRange = ({ inProgress, ...props }) => {
	const [ action, setAction ] = React.useState(() => {});
	const onMountWrapper = React.useCallback((setState) => setAction(() => setState), [
		setAction,
	]);
	React.useEffect(() => {
		if (utilsCheckFunc(action)) {
			action((currentState) => ({
				...currentState,
				inProgress: inProgress
			}));
		}
	}, [
		inProgress,
		action,
	]);

	return <StyledWrapper onMount={onMountWrapper} { ...props } error={props.error && <ErrorText title={props.error} />} />;
};

DateRange = React.memo(DateRange);
DateRange.defaultProps = {
};
DateRange.propTypes = {
};

export default DateRange;
