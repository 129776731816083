import styled from 'styled-components';
import Form from '@nest-datum-ui/Form/Form.jsx';

const Wrapper = styled(Form)`
	& .form-children__wrapper .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiAutocomplete-inputRoot {
		padding: 0px !important;
		// margin: 9px 0px !important;
	}
	&& .input-phone__error-wrapper{
		justify-content: center;
		margin-top: 18px;
	}
	&& .dateRange-form .input-date-range__error-wrapper{
		text-align: left;
		margin-top: 3px;
		margin-right: 14px;
		margin-bottom: 0;
		margin-left: 14px;
		justify-content: flex-start;
	}
`;

export default Wrapper;