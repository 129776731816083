import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import { 
	strFilled as utilsCheckStrFilled,
	strEmail as utilsCheckStrEmail, 
	strUserName as utilsCheckStrUserName,
} from '@nest-datum-utils/check';

const firstStep = async (e, storeName, apiUrl, setStep = () => {}) => {
	const validatedData = await utilsValidateStore(storeName, {
		email: [{
			text: 'Max length 100 symbols',
			check: [ utilsCheckStrEmail ],
			isRequired: true,
		},{
			text: 'Required field. Fill in your Email',
			check: [ utilsCheckStrFilled ],
			isRequired: true,
		}],
		firstname: [{
			text: 'Fill in your First Name in latin',
			check: [ utilsCheckStrUserName ],
			isRequired: true,
		},{
			text: 'Required field. Fill in your First Name',
			check: [ utilsCheckStrFilled ],
			isRequired: true,
		}],
		lastname: [{
			text: 'Fill in your Last Name in latin',
			check: [ utilsCheckStrUserName ],
			isRequired: true,
		},{
			text: 'Required field. Fill in your Last Name',
			check: [ utilsCheckStrFilled ],
			isRequired: true,
		}],
	});

	if (validatedData) {
		setStep((currentState) => currentState + 1);
	}
};

export default firstStep;
