import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
	selectorMainExtract,
	actionApiListGet,
	actionApiListPurge,
	actionApiListPush,
} from '@nest-datum-ui/Store';
import { arrToObj as formatArrToObj } from '@nest-datum-utils/format';
import { objFilled as utilsCheckObjFilled } from '@nest-datum-utils/check';
import AddItem from './AddItem';
import StyledWrapper from './Styled/Wrapper.jsx';
import CustomPaper from './Styled/Paper.jsx';
import ErrorText from 'components/Input/Error';

let StudyField = ({ storeName, ...props }) => {
	const data = useSelector(selectorMainExtract([ 'api', 'list', 'studyField', 'data' ]));
	const dataMemo = React.useMemo(() => (data || []).map((item) => item.value), [
		data,
	]);
	const dataMemoObj = React.useMemo(() => formatArrToObj(data, 'value'), [
		data,
	]);
	const onAdd = React.useCallback((e, value, index) => actionApiListPush(storeName, utilsCheckObjFilled(dataMemoObj[value])
		? dataMemoObj[value]
		: ({
			id: uuidv4(),
			value,
		}))(), [
		storeName,
		dataMemoObj,
	]);

	React.useEffect(() => {
		actionApiListGet('studyField', {
			apiUrl: `${process.env.URL_API_FORMS}/field/content`,
			page: 1,
			limit: 200,
			filter: {
				fieldId: 'happ-forms-field-education-stady',
				isUnique: true,
			},
		})();
	}, [
	]);

	React.useEffect(() => () => {
		actionApiListPurge('studyField')();
	}, [
	]);

	return <StyledWrapper 
		{ ...props } 
		PaperComponent={CustomPaper} 
		options={dataMemo}
		onAdd={onAdd} 
		error={props.error && <ErrorText title={props.error} />} />;
};

StudyField = React.memo(StudyField);
StudyField.defaultProps = {
	options: [],
	AddItemComponent: AddItem,
	addNewValue: true,
};
StudyField.propTypes = {
};

export default StudyField;
