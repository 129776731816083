
const userOption = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Options',

	pageUrl: 'users/options',
	pageTitle: 'Options',

	fieldsBlock: true,
	fieldsBlockTitle: 'Options:',
};

export default userOption;
