import React from 'react';
import { arr as utilsCheckArr } from '@nest-datum-utils/check';

const renderValue = (valueState, data) => {
	const dataProcessed = {};

	data.forEach((value, index) => {
		dataProcessed[value.id] = value;
	});

	return valueState.map((value, index) => <React.Fragment key={utilsCheckArr(value)
			? value[0]
			: value}>
			{dataProcessed[utilsCheckArr(value)
				? value[0]
				: value].title}
			{(valueState.length - 1 > index)
				? ','
				: ''}
		</React.Fragment>);
};

export default renderValue;
