import axios from 'axios';
import Store, { 
	hookUrlNavigate,
	actionApiFormProp,
	actionApiFormPurge
} from '@nest-datum-ui/Store';
import { 
	arrToObj as formatArrToObj,
	urlApiStr as  utilsFormatUrlApiStr, 
	httpErrorMessage as utilsFormatHttpErrorMessage, 
} from '@nest-datum-utils/format';
import { store as utilsValidateStore } from '@nest-datum-utils/validate';
import { strFilled as utilsCheckStrFilled } from '@nest-datum-utils/check';
import { strTitle as utilsCheckStrTitle } from '@nest-datum-utils/check';

const submit = async (e, storeName, apiUrl, nextPageUrl) => {
	const userData = Store()
		.getState()
		.api
		.form['sso-sign-in'] || {};

	const validatedData = await utilsValidateStore(storeName, {
		jobKind: [{
			text: 'Fill in your job title correctly. Max length 60 symbols',
			check: [ utilsCheckStrTitle ],
			isRequired: true,
		},{
			text: 'Required field. Fill in your job title',
			check: [ utilsCheckStrFilled ],
			isRequired: true,
		}],
	});

	// if (validatedData) {
	// 	localStorage.setItem('jobKind', validatedData['jobKind']);
		
	// 	actionApiFormCreate(storeName, { /*apiUrl,*/ })(() => {
	// 		actionApiFormProp(storeName, 'loader', false)();
	// 	});

	// 	hookUrlNavigate(nextPageUrl);
	// }

	if (validatedData) {
		localStorage.setItem('jobKind', validatedData['jobKind']);

		actionApiFormProp(storeName, 'loader', true)(async () => {
			try {
				const userUserOptionsObj = formatArrToObj(userData['userUserOptions'], 'userOptionId');
				await axios.patch(utilsFormatUrlApiStr(`${apiUrl}/${userUserOptionsObj['happ-sso-user-option-position']['id']}/option`), {
					content: validatedData['jobKind'],
				});

				setTimeout(() => {
					actionApiFormPurge(storeName)();
				}, 0);

				hookUrlNavigate("/get-started/location");
			}
			catch (err) {
				actionApiFormProp(storeName, 'loader', false)();

				throw new Error(utilsFormatHttpErrorMessage(err, apiUrl));
			}
		});
	}
};

export default submit;
