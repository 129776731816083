import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import { urlApiStr as utilsFormatUrlApiStr } from '@nest-datum-utils/format';
import MuiAvatar from '@mui/material/Avatar';
import IconButtonUpload from 'components/IconButton/Upload';
import StyledWrapper from './Styled/Wrapper.jsx';

let Avatar = ({ withUpload, className, ...props }) => {
	const avatar = useSelector(selectorMainExtract([ 'api', 'form', 'avatar' ]));
	const avatarPath = (avatar || {})['path'];
	const src = avatarPath
		? `${utilsFormatUrlApiStr(process.env.URL_API_FILES + avatarPath)}&rand=${uuidv4()}`
		: '';

	return <StyledWrapper className={className}>
		<MuiAvatar { ...props } { ...src ? { src, alt: '' } : {} } />
		{(withUpload === true) && <IconButtonUpload />}
	</StyledWrapper>;
};

Avatar = React.memo(Avatar);
Avatar.defaultProps = {
};
Avatar.propTypes = {
};

export default Avatar;
