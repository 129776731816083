import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import configSignIn from '@nest-datum-ui-admin-lib/sso/src/config/signIn.js';
import ButtonLink from '@nest-datum-ui/Button/Link';
import ButtonHeader from 'components/Button/Header';
import StyledWrapper from './Styled/Wrapper.jsx';
import Grid from '@mui/material/Grid';

import { ReactComponent as SvgIconsHomeSmall } from 'static/svg/icons/dashboardMenu-small.svg';
import { ReactComponent as SvgIconsProfileSmall } from 'static/svg/icons/profileMenu-small.svg';
import { ReactComponent as SvgIconsJobsSmall } from 'static/svg/icons/jobsMenu-small.svg';
import { ReactComponent as SvgIconsCloseMenuSmall } from 'static/svg/icons/closeMenu-small-light.svg';
import { ReactComponent as SvgIconsSupportMenuSmall } from 'static/svg/icons/supportMenu-small.svg';
import { ReactComponent as SvgIconsSettingsMenuSmall } from 'static/svg/icons/settingsMenu-small.svg';
import { ReactComponent as SvgIconsSignOutMenuSmall } from 'static/svg/icons/signOutleftMenu-small.svg';

let Header = ({ menuVisible, ...props }) => {
	const { pathname } = useLocation();
	const userId = useSelector(selectorMainExtract([ 'api', 'form', configSignIn.storeName, 'id' ]));

	return <StyledWrapper container>
		<Grid container>
			<Grid
				item
				xs={false}>
				<ButtonHeader
					component={ButtonLink}
					onClick={menuVisible}
					to={`/${process.env.ROUTE_AUTHED}`}>
					<SvgIconsHomeSmall />
					<div>Dashboard</div>
				</ButtonHeader>
			</Grid>
			<Grid
				item
				xs={false}>
				<ButtonHeader
					component={ButtonLink}
					onClick={menuVisible}
					to={`/${process.env.ROUTE_AUTHED}/${userId}`}
					active={pathname.indexOf(`/${process.env.ROUTE_AUTHED}/${userId}`) === 0}>
					<SvgIconsProfileSmall />
					<div>Profile</div>
				</ButtonHeader>
			</Grid>
			<Grid
				item
				xs={false}>
				<ButtonHeader
					component={ButtonLink}
					onClick={menuVisible}
					to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_JOBS}`}>
					<SvgIconsJobsSmall />
					<div>Jobs</div>
				</ButtonHeader>
			</Grid>
			<Grid
				item
				xs={false}>
				<ButtonHeader
					component={ButtonLink}
					onClick={menuVisible}
					to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_SUPPORT}`}>
					<SvgIconsSupportMenuSmall />
					<div>Support</div>
				</ButtonHeader>
			</Grid>
			<Grid
				item
				xs={false}>
				<ButtonHeader
					component={ButtonLink}
					onClick={menuVisible}
					to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_SETTINGS}`}>
					<SvgIconsSettingsMenuSmall />
					<div>Settings</div>
				</ButtonHeader>
			</Grid>
			<Grid
				item
				xs={false}>
				<ButtonHeader
					component={ButtonLink}
					onClick={menuVisible}
					to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_SIGN_OUT}`}>
					<SvgIconsSignOutMenuSmall />
					<div>Sign Out</div>
				</ButtonHeader>
			</Grid>
			<Grid
				item
				xs={false}>
				<ButtonHeader onClick={menuVisible} className="paper-mobile_close-menu-btn">
					<SvgIconsCloseMenuSmall />
					<div>Close</div>
				</ButtonHeader>
			</Grid>
		</Grid>
	</StyledWrapper>;
};

Header = React.memo(Header);
Header.defaultProps = {
};
Header.propTypes = {
};

export default Header;
