import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
	selectorMainExtract,
	actionApiListGet,
	actionApiListPurge,
	actionApiListPush,
} from '@nest-datum-ui/Store';
import { arrToObj as formatArrToObj } from '@nest-datum-utils/format';
import { objFilled as utilsCheckObjFilled } from '@nest-datum-utils/check';
import AddItem from './AddItem';
import StyledWrapper from './Styled/Wrapper.jsx';
import CustomPaper from './Styled/Paper.jsx';
import ErrorText from 'components/Input/Error';

let Kind = ({ storeName, ...props }) => {
	const data = useSelector(selectorMainExtract([ 'api', 'list', 'kind', 'data' ]));
	const dataMemo = React.useMemo(() => (data || []).map((item) => item.userUserOptionContent), [
		data,
	]);
	const dataMemoObj = React.useMemo(() => formatArrToObj(data, 'userUserOptionContent'), [
		data,
	]);
	const onAdd = React.useCallback((e, value, index) => actionApiListPush(storeName, utilsCheckObjFilled(dataMemoObj[value])
		? dataMemoObj[value]
		: ({
			id: uuidv4(),
			value,
		}))(), [
		storeName,
		dataMemoObj,
	]);

	React.useEffect(() => {
		actionApiListGet('kind', {
			apiUrl: `${process.env.URL_API_SSO}/user`,
			page: 1,
			limit: 200,
			filter: {
				isUnique: true,
				userUserOptions: {
					userOptionId: 'happ-sso-user-option-position',
				},
			},
		})();
	}, [
	]);

	React.useEffect(() => () => {
		actionApiListPurge('kind')();
	}, [
	]);

	const onInputLocal = React.useCallback((e) => {
		e.target.value = e.target.value.toLowerCase().replace(/[^a-zA-Z ]+/g, '').substring(0, 60);
	}, []); 

	return <StyledWrapper 
		{ ...props } 
		PaperComponent={CustomPaper} 
		options={dataMemo}
		onAdd={onAdd} 
		onInput={onInputLocal}
		error={props.error && <ErrorText title={props.error} />} />;
};

Kind = React.memo(Kind);
Kind.defaultProps = {
	options: [],
	AddItemComponent: AddItem,
	addNewValue: true,
};
Kind.propTypes = {
};

export default Kind;
