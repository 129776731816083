import React from 'react';
import PropTypes from 'prop-types';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import Field from '@nest-datum-ui/Field';
import InputJobKind from 'components/Input/Job/Kind';
import ButtonPrimary from 'components/Button/Primary';
import StyledWrapper from './Styled/Wrapper.jsx';
import handlerSubmit from './handler/submit.js';

let JobKind = ({ onSubmit, ...props }) => {
	const routeName = React.useContext(ContextRoute);
	const { 
		[routeName]: { 
			form: { 
				storeName, 
				id, 
				apiUrl, 
				nextPageUrl, 
			}, 
		}, 
	} = React.useContext(ContextProps);

	const onSubmitWrapper = React.useCallback((e) => {
		handlerSubmit(e, storeName, apiUrl, nextPageUrl);
		onSubmit(e);
	}, [
		storeName,
		apiUrl,
		nextPageUrl,
		onSubmit,
	]);

	return <StyledWrapper { ...props }
		storeName={storeName} 
		id={id} 
		onSubmit={onSubmitWrapper}>
		<Field
			Component={(props) => <InputJobKind
				{ ...props }
				storeName="jobKindSelected" />}
			form={id}
			name="jobKind"
			placeholder="Job Title*" />
		<ButtonPrimary fullWidth onClick={onSubmitWrapper}>
			Next Step
		</ButtonPrimary>
	</StyledWrapper>;
};

JobKind = React.memo(JobKind);
JobKind.defaultProps = {
	onSubmit: () => {},
};
JobKind.propTypes = {
	onSubmit: PropTypes.func,
};

export default JobKind;
