
const systemStatus = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Statuses',

	pageUrl: 'systems/statuses',
	pageTitle: 'Statuses',
};

export default systemStatus;
