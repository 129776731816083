import styled from 'styled-components';
import Typography from '../../Typography.jsx';

const Wrapper = styled(Typography)`
    font-family: Jost!important;
    font-style: normal;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 23px!important;
    text-align: center;
    color: #0A0A0A;
`;

export default Wrapper;