import styled from 'styled-components';
import Paper from '../../../Paper.jsx';

const Wrapper = styled(Paper)`
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08)!important;
    border-radius: 16px!important;
    overflow: hidden;
    margin-bottom: 14px;
    padding: 12px 32px;

    && .MuiPaper-root:before{
        display: none;
    }
    && .MuiPaper-root .MuiButtonBase-root{
        padding: 0px!important;
    }
    && .MuiPaper-root{
        box-shadow: none!important;
    }
`;

export default Wrapper;