import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { 
	selectorMainExtract,
	actionApiListGet,
	actionApiListPurge,
} from '@nest-datum-ui/Store';
import { 
	arrFilled as utilsCheckArrFilled,
	strIdExists as utilsCheckStrIdExists, 
} from '@nest-datum-utils/check';
import StyledWrapper from './Styled/Wrapper.jsx';

let Workplace = (props) => {
	const [ id ] = React.useState(() => uuidv4());
	const dataTypeId = 'happ-data-type-enum-workplace';
	const storeName = `dataType${dataTypeId}_${id}`;
	const data = useSelector(selectorMainExtract([ 'api', 'list', storeName, 'data', 0, 'typeTypeTypeOptions' ]));

	React.useEffect(() => {
		if (utilsCheckStrIdExists(dataTypeId)) {
			actionApiListGet(storeName, {
				apiUrl: `${process.env.URL_API_DATA_TYPE}/type/option`,
				page: 1,
				limit: 9999,
				filter: {
					typeId: dataTypeId,
					typeTypeTypeOptions: {
						typeId: dataTypeId,
					},
				},
			})();
		}
	}, [
		dataTypeId,
		storeName,
	]);

	React.useEffect(() => () => {
		actionApiListPurge(storeName)();
	}, [
		storeName,
	]);

	return utilsCheckArrFilled(data)
		&& <StyledWrapper { ...props }>
			{data.map((item) => ({ id: item.id, title: item.content }))}
		</StyledWrapper>;
};

Workplace = React.memo(Workplace);
Workplace.defaultProps = {
};
Workplace.propTypes = {
};

export default Workplace;
