import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import StyledWrapper from './Styled/Wrapper.jsx';
import TypographyBody from 'components/Typography/Body';
import Typography from 'components/Typography';
import IconButtonAdd from 'components/IconButton/Add';
import IconButtonEdit from 'components/IconButton/Edit';
import { strPlaceholder as utilsFormatStrPlaceholder } from '@nest-datum-utils/format';

let Preview = ({ 
	to, 
	title, 
	children, 
	isDataAdded, 
	hideEdit, 
	setDataVisible, 
	isVisible, 
	isExperienceBlock, 
	...props 
}) => {
	const { pathname } = useLocation();
	const urlParams = useParams();
	const isExperienceUrl = utilsFormatStrPlaceholder(`/${process.env.ROUTE_AUTHED}/:userId/${process.env.ROUTE_EXPERIENCE}`, urlParams) === pathname;
	const isExperience = isExperienceBlock 
		? 'preview-paper_experience-box-true'
		: 'preview-paper_experience-box-false';

	return <StyledWrapper 
		{ ...props } 
		className={(isDataAdded !== 0) 
			? `preview-paper_data-added ${isExperience}` 
			: `preview-paper_data-null ${isExperience}`}>
		<Grid 
			container 
			className={(isExperienceUrl === true) 
				? 'preview-paper_experience-page-grid' 
				: '' }>
			<Grid item>
				<TypographyBody>
					{title}
				</TypographyBody>
				{ (isDataAdded === 0 || isExperienceUrl === true) && <IconButtonAdd to={to} /> }
			</Grid>
			<Grid 
				item 
				className='paper-box-info_box-of-items'>
				<Grid 
					container 
					className={(hideEdit === true) 
						? 'paper-box-info_list-of-items paper-box-info_list-of-items-line' 
						: 'paper-box-info_list-of-items'}>
					{!hideEdit ? <>
						<Grid item md={10}>
							{children}
						</Grid>
						<Grid item md={2}>
						{isDataAdded !== 0 
							&& <IconButtonEdit to={to} /> }
						</Grid>
					</> 
					: children}
					{(isDataAdded > 3 
						&& children.length > 3)
						&& <Grid item className="paper-education_view-all" onClick={() => setDataVisible()}>
							<Typography>{ !isVisible ? "View all" : "Hide" }</Typography>
						</Grid>}
				</Grid>
			</Grid>
		</Grid>
	</StyledWrapper>;
};

Preview = React.memo(Preview);
Preview.defaultProps = {
};
Preview.propTypes = {
};

export default Preview;
