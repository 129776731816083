import styled from 'styled-components';
import Typography from '../../../Typography.jsx';

const Wrapper = styled(Typography)`
    font-family: Jost!important;
    font-style: normal;
    font-weight: 400!important;
    font-size: 24px!important;
    line-height: 150%!important;
    text-align: center;
    letter-spacing: -0.011em;
    color: #0A0A0A;
`;

export default Wrapper;