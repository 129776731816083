
const config = {
	form: {
		storeName: 'get-started-job-quickOffers',
		id: 'get-started-job-quickOffers',
		apiUrl: `${process.env.URL_API_JOB}/quick-offers`,
		nextPageUrl: `/${process.env.ROUTE_SiGN_UP}`,
	},
};

export default config;
