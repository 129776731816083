import styled from 'styled-components';
import IconButton from '../../../IconButton.jsx';

const Wrapper = styled(IconButton)`
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px!important;
    width: 30px;
    height: 30px;
`;

export default Wrapper;