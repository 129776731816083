import styled from 'styled-components';
import Paper from '@mui/material/Paper';

const Wrapper = styled(Paper)`
    background: #FFFFFF;
    border: 0px!important;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08)!important;
`;

export default Wrapper;