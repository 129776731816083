import React from 'react';
import PaperNotificationItem from 'components/Paper/Notification/Item';
import StyledWrapper from './Styled/Wrapper.jsx';

let Notification = (props) => {
	const initialFilter = React.useMemo(() => ({
		company: {
			isDeleted: false,
		},
	}), [
	]);

	return <StyledWrapper { ...props } 
		loadOnFirstRender
		initialFilter={initialFilter}
		mobileWithDots={false}>
		<PaperNotificationItem />
	</StyledWrapper>;
};

Notification = React.memo(Notification);
Notification.defaultProps = {
};
Notification.propTypes = {
};

export default Notification;
