import styled from 'styled-components';
import Box from '@mui/material/Box';

const Wrapper = styled(Box)`
	width: 100%;

	@media (max-width: 600px) {
		&& .paper-profile_info-container{
			padding-top: 0px;
			padding-left: 0px;
			width: 100%!important;
			margin-left: 33px;
			margin-right: 33px;
		}
		&& .paper-profile_info-grid{
			margin-top: 0px;
			margin-left: 0px;
			width: 100%;
		}
	}
`;

export default Wrapper;