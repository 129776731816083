import React from 'react';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import { ReactComponent as SvgLastStep } from 'static/svg/cv-last-step.svg';
import Typography from 'components/Typography';
import TypographyTitle from 'components/Typography/Title';
import ButtonSecondary from 'components/Button/Secondary';
import ButtonInfo from 'components/Button/Info';
import StyledWrapper from './Styled/Wrapper.jsx';

let Complete = (props) => {
	const routeName = React.useContext(ContextRoute);
	const { [routeName]: { lastStep: { nextPageUrl } } } = React.useContext(ContextProps);

	return <StyledWrapper { ...props }>
		<SvgLastStep />
		<TypographyTitle className="paper-last-step_complete-title">
			Last step - verify your email!
		</TypographyTitle>
		<Typography className="paper-last-step_complete-subtitle">
			To get access to your account verify email.<br />
			Check your mail box and follow the instruction.
		</Typography>
		<ButtonSecondary to={nextPageUrl} className="paper-last-step_complete-btn">
			Create Profile
		</ButtonSecondary>
		<ButtonInfo className="paper-last-step_send-again-btn">
			Send again
		</ButtonInfo>
	</StyledWrapper>;
};

Complete = React.memo(Complete);
Complete.defaultProps = {
};
Complete.propTypes = {
};

export default Complete;
