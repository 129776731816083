import React from 'react';
import Typography from 'components/Typography';
import StyledWrapper from './Styled/Wrapper.jsx';

let data = []

let JobType = (props) => {
	return <StyledWrapper { ...props } title="Job type" to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}/${process.env.ROUTE_JOB_TYPE}`} isDataAdded={data.length !== 0 ? 1: 0}>
		<Typography>
			Add your desirable job type
		</Typography>
	</StyledWrapper>;
};

JobType = React.memo(JobType);
JobType.defaultProps = {
	isDataAdded: false
};
JobType.propTypes = {
};

export default JobType;
