
const config = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Providers',

	displayInHeaderTabMenu: true,
	orderInHeaderTabMenu: 2,
	headerTabMenuTitle: 'Providers',

	pageUrl: 'providers',
	pageTitle: 'Providers',
};

export default config;
