import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';
import Typography from 'components/Typography';
import IconButtonEdit from 'components/IconButton/Edit';
import IconButtonDrop from 'components/IconButton/Drop';
import Grid from '@mui/material/Grid';

let Item = ({ 
	dateStr, 
	date,
	descriptionStr, 
	description,
	title, 
	subtitle, 
	createdAt,
	...props 
}) => {
	const [ isVisible, setVisible ] = React.useState(() => false);
	const setDataVisible = React.useCallback((e) => setVisible((currentState) => !currentState), [
		setVisible,
	]);

	return <StyledWrapper { ...props }>
		<Grid container>
			<Grid item>
				<Typography>
					{title}
				</Typography>
				<Typography>
					{subtitle} {createdAt}
				</Typography>
				{description
					&& <Typography className={!isVisible 
						? `hiddenOnMobile ${descriptionStr}` 
						: `${descriptionStr}`}>
						{description}
					</Typography>}
				{(isVisible === false && description !== undefined) && 
					<Typography 
						className="paper-profile_btn-read-more hiddenOnDesktop" 
						onClick={setDataVisible}>
						Read more
					</Typography> }
			</Grid>
			<Grid item>
				<IconButtonEdit />
				<IconButtonDrop />
			</Grid>
		</Grid>
	</StyledWrapper>;
};

Item = React.memo(Item);
Item.defaultProps = {
	dateStr: "",
	descriptionStr: "",
};
Item.propTypes = {
};

export default Item;
