
const userStatus = {
	displayInBreadcrumbsMenu: true,
	breadcrumbsMenuTitle: 'Statuses',

	pageUrl: 'users/statuses',
	pageTitle: 'Options',
};

export default userStatus;
