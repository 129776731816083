import styled from 'styled-components';

const Box = styled.div`
    && label{
        display: flex!important;
    }

    && span.MuiTypography-root{
        margin-bottom: 0px;
    }
`;

export default Box;