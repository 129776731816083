import styled from 'styled-components';
import Form from '../../Form.jsx';

const Wrapper = styled(Form)`
    && .form-children__wrapper{
        gap: 18px;
        display: flex;
        flex-direction: column;
    }
    
`;

export default Wrapper;