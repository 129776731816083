import React from 'react';
import { ContextRoute } from '@nest-datum-ui/Context';
import TermsDoc from 'components/Paper/Docs/Terms';

let Terms = () => {
	return <ContextRoute.Provider>
		<TermsDoc />
	</ContextRoute.Provider>;
};

Terms = React.memo(Terms);
Terms.defaultProps = {
};
Terms.propTypes = {
};

export default Terms;
