import styled from 'styled-components';
import Grid from '@mui/material/Grid';

const Wrapper = styled(Grid)`
	margin-bottom: 24px;
	
	& > .MuiGrid-item:nth-child(1){
		max-width: 743px;
	}
	& > .MuiGrid-item:nth-child(2){
		max-width: 400px;
		flex: 1;
	}

	@media (max-width: 600px) {
		& > .MuiGrid-item:nth-child(1),
		& > .MuiGrid-item:nth-child(2){
			max-width: 100%;
		}
	}
`;

export default Wrapper;