import styled from 'styled-components';
import Paper from '../../Paper.jsx';

const Wrapper = styled(Paper)`
    background: #FFFFFF;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12)!important;
    border-radius: 16px!important;
    padding: 12px 30px;
    margin-bottom: 18px;
`;

export default Wrapper;