import React from 'react';
import PropTypes from 'prop-types';
import StyledWrapper from './Styled/Wrapper.jsx';
import ErrorText from 'components/Input/Error';

let Location = (props) => {
	return <StyledWrapper { ...props } error={props.error && <ErrorText title={props.error} />}  />;
};

Location = React.memo(Location);
Location.defaultProps = {
	placeholder: 'Location',
	name: 'location',
};
Location.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
};

export default Location;
