import styled from 'styled-components';
import Box from '@mui/material/Box';

const Wrapper = styled(Box)`
	width: 100%;
	max-width: 480px;
	padding: 0px 25px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow-y: auto;

	@media (max-width: 600px) {
		max-width: 100%;
    }
`;

export default Wrapper;