import React from 'react';
import { 
	Outlet, 
	useLocation,
	useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorMainExtract } from '@nest-datum-ui/Store';
import configSignIn from '@nest-datum-ui-admin-lib/sso/src/config/signIn.js';
import { strPlaceholder as utilsFormatStrPlaceholder } from '@nest-datum-utils/format';
import Grid from '@mui/material/Grid';
import MenuProfile from 'components/Menu/Profile';
import MenuProfileButtons from 'components/Menu/Profile/Buttons';
import PaperProfileMenu from 'components/Paper/Profile/Menu';
import PaperGotHiredMin from 'components/Paper/GotHired/Min';
import StyledWrapper from './Styled/Wrapper.jsx';

const collapsed = [
	`/${process.env.ROUTE_AUTHED}/:userId/${process.env.ROUTE_EDIT}`,
	`/${process.env.ROUTE_AUTHED}/:userId/${process.env.ROUTE_GOT_HIRED}`,
];
let Profile = () => {
	const { pathname } = useLocation();
	const urlParams = useParams();
	const isCollapsed = (collapsed.filter((item) => utilsFormatStrPlaceholder(item, urlParams) === pathname)).length > 0;
	const userId = useSelector(selectorMainExtract([ 'api', 'form', configSignIn.storeName, 'id' ]));
	const hideBtnsSection = pathname === `/${process.env.ROUTE_AUTHED}/${userId}`;

	return <StyledWrapper>
		{!isCollapsed
			? <React.Fragment>
				<MenuProfile className='hiddenOnDesktop'/>
				<PaperGotHiredMin />
				<Grid container spacing={3} className="paper-profile_info-grid">
					<Grid item md={3} className='hiddenOnMobile'>
						<PaperProfileMenu hideBtnsSection={hideBtnsSection ? true : false} />
					</Grid>
					<Grid item md={9} className="paper-profile_info-container">
						<Outlet />
						{ hideBtnsSection === true && <MenuProfileButtons className='hiddenOnDesktop'/> }
					</Grid>
				</Grid>
			</React.Fragment>
			: <Outlet />}
	</StyledWrapper>;
};

Profile = React.memo(Profile);
Profile.defaultProps = {
	hideBtnsSection: false
};
Profile.propTypes = {
};

export default Profile;
