import React from 'react';
import { 
	ContextProps,
	ContextRoute, 
} from '@nest-datum-ui/Context';
import { 
	selectorMainExtract
} from '@nest-datum-ui/Store';
import { ReactComponent as SvgCvBuilder } from 'static/svg/cv-builder.svg';
import { useSelector } from 'react-redux';
import Typography from 'components/Typography';
import TypographyTitle from 'components/Typography/Title';
import ButtonPrimary from 'components/Button/Primary';
import StyledWrapper from './Styled/Wrapper.jsx';

let Complete = (props) => {
	const routeName = React.useContext(ContextRoute);
	const userId = useSelector(selectorMainExtract([ 'api', 'form', 'sso-sign-in', 'id' ]));
	const { [routeName]: { form: { nextPageUrl } } } = React.useContext(ContextProps);

	return <StyledWrapper { ...props }>
		<SvgCvBuilder />
		<TypographyTitle className="paper-uploadcv_complete-title">
			Сomplete!
		</TypographyTitle>
		<Typography className="paper-uploadcv_complete-subtitle">
			Welcome to HAPP.<br />
			Your profile was successfully created.<br />
			Let's find a great job together.<br />
		</Typography>
		<ButtonPrimary to={nextPageUrl+"/"+userId} className="paper-uploadcv_complete-btn">
			Review Profile
		</ButtonPrimary>
	</StyledWrapper>;
};

Complete = React.memo(Complete);
Complete.defaultProps = {
};
Complete.propTypes = {
};

export default Complete;
