import styled from 'styled-components';
import Typography from '../../../Typography.jsx';

const Wrapper = styled(Typography)`
    color: #0A0A0A;
    font-weight: 600!important;
    font-size: 24px!important;
    line-height: 35px!important;
`;

export default Wrapper;