import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    a, button{
        width: 100%;
        margin-bottom: 16px;
    }

    @media (max-width: 600px) {
		margin-left: 16px;
		margin-right: 16px;
	}
`;

export default Wrapper;