import React from 'react';
import Typography from 'components/Typography';
import StyledWrapper from './Styled/Wrapper.jsx';

let data = []

let Relocate = (props) => {
	return <StyledWrapper { ...props } title="Relocate" to={`/${process.env.ROUTE_AUTHED}/${process.env.ROUTE_CV_UPDATE}/${process.env.ROUTE_RELOCATE}`} isDataAdded={data.length !== 0 ? 1: 0}>
		<Typography>
			Add relocation option
		</Typography>
	</StyledWrapper>;
};

Relocate = React.memo(Relocate);
Relocate.defaultProps = {
	isDataAdded: false
};
Relocate.propTypes = {
};

export default Relocate;
