import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';

let Terms = (props) => {
	return <StyledWrapper { ...props }>
		Terms page
	</StyledWrapper>;
};

Terms = React.memo(Terms);
Terms.defaultProps = {
};
Terms.propTypes = {
};

export default Terms;
