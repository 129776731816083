import React from 'react';
import StyledWrapper from './Styled/Wrapper.jsx';

let Server = (props) => {
	return <StyledWrapper { ...props }  />;
};
Server = React.memo(Server);
Server.defaultProps = {
};
Server.propTypes = {
};

export default Server;
