
const SignIn = {
	pageTitle: 'Sign in',
	pageFullUrl: `${process.env.URL_UI}/${process.env.ROUTE_SiGN_IN}`,
			
	id: 'sso-sign-in',
	storeName: 'sso-sign-in',
	apiUrl: 'user/login',
};

export default SignIn;
